import styles from './Priority.scss';

import React, {} from 'react';
import Carousel2 from '../Carousel2';
import IconSmall from '../../ui/IconSmall';
import TitleReveal from '../../ui/TitleReveal';

import { useTranslation } from "react-i18next";

const Priority = ({ standards }) => {
  const [t, i18n] = useTranslation("global");

  return (
    <section className='width-100 priority-wrapper flex'>
      <div className='constrain-width padding-sides priority-grid'>
        <Carousel2 images={['peliroja','tom', 'anna','marta','black', 'young-woman_7','asian','tatiana','agathe']} />
        
        <div className='priority-title'>
          <TitleReveal text={t("priority.title")} />
          <ul className='card-list flex-column'>
            <IconSmall title={t("priority.priority-1.title")} description={t("priority.priority-1.description")} icon="tie-2.svg" alignement="left" theme="accent" />
            <IconSmall title={t("priority.priority-2.title")} description={t("priority.priority-2.description")} icon="service.svg" alignement="left" theme="accent" />
            <IconSmall title={t("priority.priority-3.title")} description={t("priority.priority-3.description")} icon="time.svg" alignement="left" theme="accent" />
            <IconSmall title={t("priority.priority-4.title")} description={t("priority.priority-4.description")} icon="micro.svg" alignement="left" theme="accent" />
            <IconSmall title={t("priority.priority-5.title")} description={t("priority.priority-5.description")} icon="valet.svg" alignement="left" theme="accent" />
            <IconSmall title={t("priority.priority-6.title")} description={t("priority.priority-6.description")} icon="star.svg" alignement="left" theme="accent" />
          </ul>
        </div>
      </div>
      <img className='triangle-top' src='img/triangle.svg' alt='' />
      <img className='triangle-bottom' src='img/triangle.svg' alt='' />
    </section>
  );
};

export default Priority;