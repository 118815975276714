import styles from './Hero.scss';

import Logo from '../Logo';
import Title from '../../ui/Title';
import Tagline from '../../ui/Tagline';
import Scroll from '../../ui/Scroll';

import React, { useEffect, useRef } from 'react';

import gsap from 'gsap';

const Hero = ( { title, tagline, img, height, svg }) => {

    const imageRef = useRef(null);

    const dynamicStyle = {
        height: height || '100%', // Default to 100px if height is not provided
    };

    useEffect(() => {

        gsap.set(imageRef.current, { opacity: 0})
        gsap.to(imageRef.current, {
            opacity: 0.2,
            duration: 3,
            delay: 2,
        });

    }, [])


    return (
        <section className='hero' style={dynamicStyle}>
            <div className='hero-wrapper'>

                <img className='hero-image' src={img} alt={title} title={title} ref={imageRef}></img>

                <div className='hero-text flex padding-sides'>
                    <Logo  />
                    <Title text={title} />
                    <Tagline text={tagline} />
                    <Scroll />
                </div>
                
            </div>
            <img className='triangle-bottom' src={svg} alt=''></img>
        </section>
    )
};
export default Hero