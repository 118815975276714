import axios from 'axios';

// Api Routes to Backend

export const ApiRoutes = {
    jobOffer: '/job/ads',
    quoteClient : '/client/quote',
    sendEmail : '/email/send'
};

// Create Client BE

const apiBmgBeClient = axios.create({
    baseURL: process.env.REACT_APP_BMG_BACKEND_API_URL,
    headers: {
      'Content-Type': 'application/json', 
    },   
  });  
  
// Create Client STRAPI

const apiBmgStrapiClient = axios.create({
    baseURL: process.env.REACT_APP_BMG_STRAPI_API_URL+'/api',
    headers: {
      'Content-Type': 'application/json', 
      'Accept-Language' : process.env.REACT_APP_DEFAULT_LANG,
      'Authorization' : `Bearer ${process.env.REACT_APP_BMG_STRAPI_API_TOKEN}`
    },   
  });    

  export const ListJobOffers = async (lang) => {
    try {        
      const response = await apiBmgBeClient.post(ApiRoutes.jobOffer, { lang }, {});
      if (response && response.data && response.data.success) {
        //console.log(response.data.value);
        return response.data.value        
      }
      return [];
    } catch (error) {
      console.error('API ListJobOffers POST Error',error);
    }
  };

  export const ListStrapiSection = async (section, lang, options = {}) => {
    try {        
      const url = section + '?locale='+lang+process.env.REACT_APP_BMG_STRAPI_API_PARAMS;
      const response = await apiBmgStrapiClient.get(url, {}, options);
      if (response && response.data && response.data.data) {
        return response.data.data;
      }
      return [];
    } catch (error) {
      console.error('API ListStrapiSection POST Error',error);
    }
  };

  export const SendContactForm = async (data) => {
    try {      
      let postData = {...data,secureKey:process.env.REACT_APP_BMG_EMAIL_SECURE_KEY}
      const response = await apiBmgBeClient.post(ApiRoutes.sendEmail, postData, {});
      if (response && response.data) {
        return response.data.success
      }
    } catch (error) {
      console.error('API SendContactForm POST Error',error);
    }
  }


  export const SendClientQuote = async (data) => {
    try {        
      const response = await apiBmgBeClient.post(ApiRoutes.quoteClient, data, {});
      if (response && response.data) {
        return response.data
      }
      return {};
    } catch (error) {
      console.error('API SendClientQuote POST Error',error);
    }
  };