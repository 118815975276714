import styles from './TitleUp.scss';
import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const TitleUp = ({ text, theme = "white" }) => {
    const titleRef = useRef(null);
    const lineRef = useRef(null);
  
    useEffect(() => {
      const titleElement = titleRef.current;
      const lineElement = lineRef.current;
  
      if (titleElement) {
        // Replacing the title-animation class with a GSAP animation
        gsap.fromTo(
          titleElement,
          { y: '200%', opacity: 1 }, // Initial state: translated down and invisible
          {
            y: '0%', // Final state: normal position
            opacity: 1, // Fully visible
            ease: 'power3.out',
            duration: 1.5,
            scrollTrigger: {
              trigger: titleElement,
              start: 'top+=50 bottom', // Start animation when 100px of the element enters the bottom of the viewport
              end: 'top+=350 bottom', // End animation when 300px of the element has entered the viewport
              toggleActions: 'play reverse play reverse', // Play and reverse the animation on scroll up/down
              scrub: true, // Smooth scrubbing with scroll
              markers: false, // Set to true to see the markers for debugging
            },
          }
        );
      }
  
      if (lineElement) {
        // Replacing the line-animation class with a GSAP animation
        gsap.fromTo(
          lineElement,
          { scaleX: 0, transformOrigin: 'left' }, // Initial state: scaled down to 0 horizontally
          {
            scaleX: 1, // Final state: fully scaled horizontally
            ease: 'power3.out',
            duration: 1.5,
            scrollTrigger: {
              trigger: lineElement,
              start: 'top+=80 bottom', // Start animation when 50px of the element enters the bottom of the viewport
              end: 'top+=200 bottom', // End animation when 200px of the element has entered the viewport
              toggleActions: 'play reverse play reverse', // Play and reverse the animation on scroll up/down
              scrub: true, // Smooth scrubbing with scroll
              markers: false, // Set to true to see the markers for debugging
            },
          }
        );
      }
  
      // Cleanup function to remove ScrollTrigger on component unmount
      return () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    }, []);
  
    // Determine the reveal class based on theme
    const revealClass =
      theme === 'accent'
        ? 'text-accent'
        : theme === 'black'
        ? 'text-dark'
        : 'text-white';
  
    return (
      <div className='title mask'>
        {/* Title element */}
        <h2 ref={titleRef} className={revealClass}>
          <span>{text}</span>
        </h2>
  
        {/* Line element */}
        <div ref={lineRef} className='line'>
          {/* Content that you want to scale */}
        </div>
      </div>
    );
  };

export default TitleUp;

