import styles from './Form.scss';

import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from "react-i18next";

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import jsonFR from '../../../translations/fr/global.json'; // Import the JSON file
import { serviceArray } from '../../../utils/constants';
import { Languages } from '../../../utils/languages';

import TitleReveal from '../../ui/TitleReveal';
import Button from '../../ui/Button';
import TextField from '../../ui/TextField';
import TextSelect from '../../ui/TextSelect';
import Dropdown from '../../ui/Dropdown';
import TextArea from '../../ui/TextArea';
import RadioButton from "../../ui/RadioButton";
import DateComponent from '../../ui/DateComponent';
import HoursRange from '../../ui/HoursRange';
import Alert from '../../ui/Alert';
import { SendClientQuote } from '../../../services/Api';

import gsap from 'gsap';

const wait = function (duration = 1000) {
    return new Promise((resolve) => {
        window.setTimeout(resolve, duration);
    });
}

const Form = () => {
    const { register, formState, handleSubmit } = useForm({mode: 'onTouched'});
    const { t, i18n } = useTranslation("global");

    const formRef = useRef();
    //const alertRef = useRef(null)

    // for multiple selection
    const [selectedLanguages, setSelectedLanguages] = useState(['fr']);
    const [selectedOtherLanguage, setSelectedOtherLanguage] = useState('');

    // for date picker
    const [date, setDate] = useState(new Date());

    const methods = useForm();

    const [hostessNumber, setHostessNumber] = useState([0, 2]);
    const [hostessInput, setHostessInput] = useState(2); // Sync with the second slider value
    const [hostNumber, setHostNumber] = useState([0, 2]);
    const [hostInput, setHostInput] = useState(2); // Sync with the second slider value

    const [firstHourInput, setFirstHourInput] = useState(8);
    const [lastHourInput, setLastHourInput] = useState(18);
    const [eventDate, setEventDate] = useState(null);

    const getValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    // For the Alert component
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertContent, setAlertContent] = useState({
        title: '',
        description: '',
        color: '',
    });
    const [alertTrigger, setAlertTrigger] = useState(0);

    const showAlert = (title, description, color) => {
        setAlertContent({ title, description, color });
        setAlertTrigger(prev => prev + 1);
        setAlertVisible(true);
    };
    const onSubmit = async data => {
        await wait(1000); // Juste pour simuler le temps de réponse du serveur !

        // Passing extra datas
        //console.log('selectedLanguages' , selectedLanguages)

        let finalLanguage = [];
        finalLanguage = selectedLanguages.push(selectedOtherLanguage);

        data.eventLanguages = [...new Set(selectedLanguages)];
        
        //data.eventLanguages = selectedLanguages;
        data.eventTotalWomen = hostessNumber[1];
        data.eventTotalMen = hostNumber[1];
        data.eventStartTime = firstHourInput.toString();
        data.eventEndTime = lastHourInput.toString();
        data.eventDate = dayjs(eventDate).format('YYYY-MM-DD');

        data.eventType = getValue(jsonFR, data.eventType);

        // console.log('data.eventType',data.eventType);
        // console.log('data.eventLanguages' , data.eventLanguages)
        // console.log('finalLanguage' + finalLanguage)

        const sendReturn = await SendClientQuote(data);
        if (sendReturn && sendReturn.success) {
            showAlert(t("alert.good.title"), t("alert.good.description"), '#248320');
        } else {
            showAlert(t("alert.bad.title"), t("alert.bad.description"), '#248320');
        }
    }

    // Handle slider changes for HOSTESS and HOST
    const handleHostessNumberChange = (value) => {
        setHostessNumber(value);
        setHostessInput(value[1]); // Update the input field
    };

    const handleHostessInputChange = (event) => {
        let inputValue = parseInt(event.target.value, 10);
        if (isNaN(inputValue)) inputValue = 0; // Fallback for non-numeric input

        // Keep input within the slider's range
        inputValue = Math.max(0, Math.min(20, inputValue));
        
        setHostessInput(inputValue);
        setHostessNumber([hostessNumber[0], inputValue]); // Update the second slider value
    };

    const handleHostNumberChange = (value) => {
        setHostNumber(value);
        setHostInput(value[1]); // Update the input field
    };

    const handleHostInputChange = (event) => {
        let inputValue = parseInt(event.target.value, 10);
        if (isNaN(inputValue)) inputValue = 0; // Fallback for non-numeric input

        // Keep input within the slider's range
        inputValue = Math.max(0, Math.min(20, inputValue));
        
        setHostInput(inputValue);
        setHostNumber([hostNumber[0], inputValue]); // Update the second slider value
    };

    // Handle for radio buttons
    const handleRadioChange = event => {
        const { value } = event.target;
        const lowerCaseValue = value.toLowerCase(); // Convert the value to lowercase

        setSelectedLanguages(prevSelected => {
            if (prevSelected.includes(lowerCaseValue)) {
                // Remove the value if already selected (toggle off)
                return prevSelected.filter(lang => lang !== lowerCaseValue);
            } else {
                // Add the value if not selected (toggle on)
                return [...prevSelected, lowerCaseValue];
            }
        });
    };

    // Get the current language from i18n
    const currentLanguage = i18n.language; // e.g., 'en', 'fr', 'it', 'de'
    // Dynamically access the language array based on the current language
    const selectedLanguageArray = Languages[currentLanguage] || []; // Fallback to an empty array if language not found
   // const languageNames = selectedLanguageArray.map(language => language.name);
   const sortedLanguageArray = selectedLanguageArray.sort((a, b) => a.name.localeCompare(b.name));

    // Handle onChange from the Dropdown component
    const handleOtherLanguageChange = (value) => {
        //console.log("Selected language:", value);
        setSelectedOtherLanguage(value); // Save selected language in state
    };

    return (
        <>
        <div className='width-100 client-wrapper flex white-bg'>
            <div className='constrain-width padding-sides'>
                <FormProvider {...methods}>
                    <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className='form-spacing'>
                            <div className='title'>
                                <TitleReveal text={t("client.form.event.main-title")} theme='black' />
                            </div>
                            <div className='form-col'>
                                <TextField placeHolder='' value='eventName' title={t("client.form.event.title")} description={t("client.form.event.title-description")}/>
                                <div className='form-col'>
                                    <TextField value='eventAddress' title={t("client.form.event.adress")} description={t("client.form.event.adress-description")}/>
                                    <TextField value='eventCity' title={t("client.form.event.city")} description={t("client.form.event.city-description")}/>
                                </div>
                            </div>
                            <div className='form-col'>
                                <TextSelect value='eventType' title={t("client.form.event.type")} description={t("client.form.event.type-description")} JSON={serviceArray}/>
                                <div className='form-col'>
                                    <DateComponent title={t("client.form.event.date")} description={t("client.form.event.date-description")}  eventDate={eventDate} setEventDate={setEventDate}  />
                                    <TextField value='eventDays' type='number' title={t("client.form.event.duration")} description={t("client.form.event.duration-description")} />
                                </div>
                            </div>
                            <div className='form-col'>
                                <div>
                                    <div className='form-col'>
                                        <div>
                                            <div className='form-title'>
                                                <label>{t("client.form.event.hostess")}</label>
                                                <div className='range-circle'>
                                                    <input
                                                        type="text"
                                                        inputMode="numeric"
                                                        min="0"
                                                        max="20"
                                                        value={hostessInput}
                                                        onChange={handleHostessInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-slider'>
                                                <RangeSlider
                                                    id="range-slider-yellow"
                                                    className="single-thumb"
                                                    min={0}
                                                    max={20}
                                                    defaultValue={[0, 2]}
                                                    value={hostessNumber}
                                                    thumbsDisabled={[true, false]}
                                                    rangeSlideDisabled={true}
                                                    onInput={handleHostessNumberChange}
                                                />
                                            </div>
                                            <h5>{t("client.form.event.hostess-description")}</h5>
                                        </div>
                                        <div>
                                            <div className='form-title'>
                                                <label>{t("client.form.event.host")}</label>
                                                <div className='range-circle'>
                                                    <input
                                                        type="text"
                                                        inputMode="numeric"
                                                        min="0"
                                                        max="20"
                                                        value={hostInput}
                                                        onChange={handleHostInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-slider'>
                                                <RangeSlider
                                                    id="range-slider-yellow"
                                                    className="single-thumb"
                                                    min={0}
                                                    max={20}
                                                    defaultValue={[0, 2]}
                                                    value={hostNumber}
                                                    thumbsDisabled={[true, false]}
                                                    rangeSlideDisabled={true}
                                                    onInput={handleHostNumberChange}
                                                />
                                            </div>
                                            <h5>{t("client.form.event.host-description")}</h5>
                                        </div>
                                    </div>
                                </div>
                                <HoursRange 
                                    firstHourInput={firstHourInput}
                                    setFirstHourInput={setFirstHourInput}
                                    lastHourInput={lastHourInput}
                                    setLastHourInput={setLastHourInput}
                                />
                            </div>
                            <div className='form-col'>
                                <div>
                                    <div className='form-role'>
                                    <TextField placeHolder={t("client.form.event.role-placeholder")} value='eventRole' required={false} title={t("client.form.event.role")} description=""/>
                                    </div>
                                    <div className='form-checkbox'>
                                        <div>
                                            <label htmlFor="language">{t("client.form.event.language")}</label>
                                            <h5></h5>
                                        </div>
                                        <div>
                                        <RadioButton
                                            value='FR'
                                            name='language'
                                            checked={selectedLanguages.includes('fr')}
                                            onChange={handleRadioChange}
                                        />
                                        <RadioButton
                                            value='EN'
                                            name='language'
                                            checked={selectedLanguages.includes('en')}
                                            onChange={handleRadioChange}
                                        />
                                        <RadioButton
                                            value='DE'
                                            name='language'
                                            checked={selectedLanguages.includes('de')}
                                            onChange={handleRadioChange}
                                        />
                                        <RadioButton
                                            value='IT'
                                            name='language'
                                            checked={selectedLanguages.includes('it')}
                                            onChange={handleRadioChange}
                                        />
                                        </div>
                                    </div>
                                    
                                    <div className='form-dropdown'>
                                        <h4 className='text-dark' style={{whiteSpace: 'nowrap', marginRight:'1em'}}>{t("client.form.event.languageList")}</h4>
                                        
                                        <Dropdown
                                            value='otherLanguage'
                                            placeholder={t("client.form.event.languageList-placeholder")}
                                            JSON={sortedLanguageArray}
                                            onChange={handleOtherLanguageChange} // Pass the onChange handler
                                            selectedValue={selectedOtherLanguage} // Dynamically set the selected value (code)
                                        />
                                    </div>
                                </div>
                                <div className='form-body'>
                                    <TextArea value='eventBody' required={false} title={t("client.form.event.comment")}/>
                                </div>
                            </div>

                            <div style={{marginTop:'6em'}} className='title'>
                                <TitleReveal text={t("client.form.info.main-title")} theme='black' />
                            </div>

                            <div className='form-col'>
                                <TextField value='clientName' title={t("client.form.info.company")} description={t("client.form.field-required")}/>
                                <TextField value='clientAddress' required={false} title={t("client.form.info.adress")} description={t("client.form.field-optional")}/>
                            </div>

                            <div className='form-col'>
                                <div className='form-col'>
                                    <TextField value='clientCity' title={t("client.form.info.city")} description={t("client.form.field-required")}/>
                                    <TextField value='clientCountry' title={t("client.form.info.country")} description={t("client.form.field-required")}/>
                                </div>
                                <div className='form-col'>
                                    <TextField value='contactFirstName' title={t("client.form.info.firstName")} description={t("client.form.field-required")}/>
                                    <TextField value='contactLastName' title={t("client.form.info.lastName")} description={t("client.form.field-required")}/>
                                </div>
                            </div>

                            <div className='form-col'>
                                <TextField value='contactEmail' type='email' title={t("client.form.info.email")} description={t("client.form.field-required")}/>
                                <TextField value='contactPhone' type='tel' title={t("client.form.info.phone")} description={t("client.form.field-required")}/>
                            </div>

                            <div style={{marginTop:'4em'}} className='flex'>
                                <Button type="submit" theme="dark" animate={false} title={t("button.form")} />
                            </div>

                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
        <Alert
            title={alertContent.title}
            description={alertContent.description}
            color={alertContent.color}
            trigger={alertTrigger}
        />
    </>
    );
};

export default Form;