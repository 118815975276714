import styles from './Services.scss'
import { useTranslation } from "react-i18next";

import IconSmall from '../../ui/IconSmall';
import TitleUp from '../../ui/TitleUp';
import Description from '../../ui/Description';

const Services = () => {
    const [t, i18n] = useTranslation("global")

    return (
        <section className='width-100 flex service-wrapper white-bg'>
            <div className='constrain-width padding-sides'>
                <div className='service-title'>
                    
                    <div className='service-header'>
                        <TitleUp text={t("services.title")} theme='black' />
                        <Description text={t("services.description")} theme='black' />
                    </div>

                </div>

                <div className='col-2 service-content'>

                    <div>
                        <ul className='service-icons flex-column card-list'>

                            <IconSmall title={t("services.service-1.title")} icon="tie-2.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-2.title")} icon="star.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-3.title")} icon="percent.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-4.title")} icon="cloakroom.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-5.title")} icon="badge.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-6.title")} icon="product.svg" alignement="left" theme="dark" />

                        </ul>
                    </div>

                    <div>
                        <ul className='service-icons flex-column card-list'>

                            <IconSmall title={t("services.service-7.title")} icon="soccer.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-8.title")} icon="flag.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-9.title")} icon="valet.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-10.title")} icon="cissors.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-11.title")} icon="message.svg" alignement="left" theme="dark" />
                            <IconSmall title={t("services.service-12.title")} icon="fiesta.svg" alignement="left" theme="dark" />

                        </ul>
                    </div>

                

                </div>
            </div>

            <img className='triangle-top' src='img/triangle.svg' alt=''></img>
            

      </section>
    )
}

export default Services