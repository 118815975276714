import { createBrowserRouter } from 'react-router-dom';
import Header from '../components/layouts/Header';
import Home from '../routes/Home';
import Member from '../routes/Member';
import Client from '../routes/Client';
import Gallery from '../routes/Gallery';
import Contact from '../routes/Contact';
import Legal from './Legal';
import Privacy from './Privacy'
import Support from './Support'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Header />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'member',
        element: <Member />
      },                             
      {
        path: 'client',
        element: <Client />
      },
      {
        path: 'gallery',
        element: <Gallery />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'legal',
        element: <Legal />
      },
      {
        path: 'privacy',
        element: <Privacy />
      },
      {
        path: 'support',
        element: <Support />
      }
    ]
  }
]);

export default router;