export const Languages = {
  en: [
    { "code": "aa", "name": "Afar" },
    { "code": "ab", "name": "Abkhazian" },
    { "code": "ae", "name": "Avestan" },
    { "code": "af", "name": "Afrikaans" },
    { "code": "ak", "name": "Akan" },
    { "code": "am", "name": "Amharic" },
    { "code": "an", "name": "Aragonese" },
    { "code": "ar", "name": "Arabic" },
    { "code": "as", "name": "Assamese" },
    { "code": "av", "name": "Avaric" },
    { "code": "ay", "name": "Aymara" },
    { "code": "az", "name": "Azerbaijani" },
    { "code": "ba", "name": "Bashkir" },
    { "code": "be", "name": "Belarusian" },
    { "code": "bg", "name": "Bulgarian" },
    { "code": "bh", "name": "Bihari languages" },
    { "code": "bi", "name": "Bislama" },
    { "code": "bm", "name": "Bambara" },
    { "code": "bn", "name": "Bengali" },
    { "code": "bo", "name": "Tibetan" },
    { "code": "br", "name": "Breton" },
    { "code": "bs", "name": "Bosnian" },
    { "code": "ca", "name": "Catalan; Valencian" },
    { "code": "ce", "name": "Chechen" },
    { "code": "ch", "name": "Chamorro" },
    { "code": "co", "name": "Corsican" },
    { "code": "cr", "name": "Cree" },
    { "code": "cs", "name": "Czech" },
    {
      "code": "cu",
      "name": "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
    },
    { "code": "cv", "name": "Chuvash" },
    { "code": "cy", "name": "Welsh" },
    { "code": "da", "name": "Danish" },
    { "code": "de", "name": "German" },
    { "code": "dv", "name": "Divehi; Dhivehi; Maldivian" },
    { "code": "dz", "name": "Dzongkha" },
    { "code": "ee", "name": "Ewe" },
    { "code": "el", "name": "Greek, Modern (1453-)" },
    { "code": "en", "name": "English" },
    { "code": "eo", "name": "Esperanto" },
    { "code": "es", "name": "Spanish; Castilian" },
    { "code": "et", "name": "Estonian" },
    { "code": "eu", "name": "Basque" },
    { "code": "fa", "name": "Persian" },
    { "code": "ff", "name": "Fulah" },
    { "code": "fi", "name": "Finnish" },
    { "code": "fj", "name": "Fijian" },
    { "code": "fo", "name": "Faroese" },
    { "code": "fr", "name": "French" },
    { "code": "fy", "name": "Western Frisian" },
    { "code": "ga", "name": "Irish" },
    { "code": "gd", "name": "Gaelic; Scomttish Gaelic" },
    { "code": "gl", "name": "Galician" },
    { "code": "gn", "name": "Guarani" },
    { "code": "gu", "name": "Gujarati" },
    { "code": "gv", "name": "Manx" },
    { "code": "ha", "name": "Hausa" },
    { "code": "he", "name": "Hebrew" },
    { "code": "hi", "name": "Hindi" },
    { "code": "ho", "name": "Hiri Motu" },
    { "code": "hr", "name": "Croatian" },
    { "code": "ht", "name": "Haitian; Haitian Creole" },
    { "code": "hu", "name": "Hungarian" },
    { "code": "hy", "name": "Armenian" },
    { "code": "hz", "name": "Herero" },
    {
      "code": "ia",
      "name": "Interlingua (International Auxiliary Language Association)"
    },
    { "code": "id", "name": "Indonesian" },
    { "code": "ie", "name": "Interlingue; Occidental" },
    { "code": "ig", "name": "Igbo" },
    { "code": "ii", "name": "Sichuan Yi; Nuosu" },
    { "code": "ik", "name": "Inupiaq" },
    { "code": "io", "name": "Ido" },
    { "code": "is", "name": "Icelandic" },
    { "code": "it", "name": "Italian" },
    { "code": "iu", "name": "Inuktitut" },
    { "code": "ja", "name": "Japanese" },
    { "code": "jv", "name": "Javanese" },
    { "code": "ka", "name": "Georgian" },
    { "code": "kg", "name": "Kongo" },
    { "code": "ki", "name": "Kikuyu; Gikuyu" },
    { "code": "kj", "name": "Kuanyama; Kwanyama" },
    { "code": "kk", "name": "Kazakh" },
    { "code": "kl", "name": "Kalaallisut; Greenlandic" },
    { "code": "km", "name": "Central Khmer" },
    { "code": "kn", "name": "Kannada" },
    { "code": "ko", "name": "Korean" },
    { "code": "kr", "name": "Kanuri" },
    { "code": "ks", "name": "Kashmiri" },
    { "code": "ku", "name": "Kurdish" },
    { "code": "kv", "name": "Komi" },
    { "code": "kw", "name": "Cornish" },
    { "code": "ky", "name": "Kirghiz; Kyrgyz" },
    { "code": "la", "name": "Latin" },
    { "code": "lb", "name": "Luxembourgish; Letzeburgesch" },
    { "code": "lg", "name": "Ganda" },
    { "code": "li", "name": "Limburgan; Limburger; Limburgish" },
    { "code": "ln", "name": "Lingala" },
    { "code": "lo", "name": "Lao" },
    { "code": "lt", "name": "Lithuanian" },
    { "code": "lu", "name": "Luba-Katanga" },
    { "code": "lv", "name": "Latvian" },
    { "code": "mg", "name": "Malagasy" },
    { "code": "mh", "name": "Marshallese" },
    { "code": "mi", "name": "Maori" },
    { "code": "mk", "name": "Macedonian" },
    { "code": "ml", "name": "Malayalam" },
    { "code": "mn", "name": "Mongolian" },
    { "code": "mr", "name": "Marathi" },
    { "code": "ms", "name": "Malay" },
    { "code": "mt", "name": "Maltese" },
    { "code": "my", "name": "Burmese" },
    { "code": "na", "name": "Nauru" },
    {
      "code": "nb",
      "name": "Bokmål, Norwegian; Norwegian Bokmål"
    },
    { "code": "nd", "name": "Ndebele, North; North Ndebele" },
    { "code": "ne", "name": "Nepali" },
    { "code": "ng", "name": "Ndonga" },
    { "code": "nl", "name": "Dutch; Flemish" },
    { "code": "nn", "name": "Norwegian Nynorsk; Nynorsk, Norwegian" },
    { "code": "no", "name": "Norwegian" },
    { "code": "nr", "name": "Ndebele, South; South Ndebele" },
    { "code": "nv", "name": "Navajo; Navaho" },
    { "code": "ny", "name": "Chichewa; Chewa; Nyanja" },
    { "code": "oc", "name": "Occitan (post 1500)" },
    { "code": "oj", "name": "Ojibwa" },
    { "code": "om", "name": "Oromo" },
    { "code": "or", "name": "Oriya" },
    { "code": "os", "name": "Ossetian; Ossetic" },
    { "code": "pa", "name": "Panjabi; Punjabi" },
    { "code": "pi", "name": "Pali" },
    { "code": "pl", "name": "Polish" },
    { "code": "ps", "name": "Pushto; Pashto" },
    { "code": "pt", "name": "Portuguese" },
    { "code": "qu", "name": "Quechua" },
    { "code": "rm", "name": "Romansh" },
    { "code": "rn", "name": "Rundi" },
    { "code": "ro", "name": "Romanian; Moldavian; Moldovan" },
    { "code": "ru", "name": "Russian" },
    { "code": "rw", "name": "Kinyarwanda" },
    { "code": "sa", "name": "Sanskrit" },
    { "code": "sc", "name": "Sardinian" },
    { "code": "sd", "name": "Sindhi" },
    { "code": "se", "name": "Northern Sami" },
    { "code": "sg", "name": "Sango" },
    { "code": "si", "name": "Sinhala; Sinhalese" },
    { "code": "sk", "name": "Slovak" },
    { "code": "sl", "name": "Slovenian" },
    { "code": "sm", "name": "Samoan" },
    { "code": "sn", "name": "Shona" },
    { "code": "so", "name": "Somali" },
    { "code": "sq", "name": "Albanian" },
    { "code": "sr", "name": "Serbian" },
    { "code": "ss", "name": "Swati" },
    { "code": "st", "name": "Sotho, Southern" },
    { "code": "su", "name": "Sundanese" },
    { "code": "sv", "name": "Swedish" },
    { "code": "sw", "name": "Swahili" },
    { "code": "ta", "name": "Tamil" },
    { "code": "te", "name": "Telugu" },
    { "code": "tg", "name": "Tajik" },
    { "code": "th", "name": "Thai" },
    { "code": "ti", "name": "Tigrinya" },
    { "code": "tk", "name": "Turkmen" },
    { "code": "tl", "name": "Tagalog" },
    { "code": "tn", "name": "Tswana" },
    { "code": "to", "name": "Tonga (Tonga Islands)" },
    { "code": "tr", "name": "Turkish" },
    { "code": "ts", "name": "Tsonga" },
    { "code": "tt", "name": "Tatar" },
    { "code": "tw", "name": "Twi" },
    { "code": "ty", "name": "Tahitian" },
    { "code": "ug", "name": "Uighur; Uyghur" },
    { "code": "uk", "name": "Ukrainian" },
    { "code": "ur", "name": "Urdu" },
    { "code": "uz", "name": "Uzbek" },
    { "code": "ve", "name": "Venda" },
    { "code": "vi", "name": "Vietnamese" },
    { "code": "vo", "name": "Volapük" },
    { "code": "wa", "name": "Walloon" },
    { "code": "wo", "name": "Wolof" },
    { "code": "xh", "name": "Xhosa" },
    { "code": "yi", "name": "Yiddish" },
    { "code": "yo", "name": "Yoruba" },
    { "code": "za", "name": "Zhuang; Chuang" },
    { "code": "zh", "name": "Chinese" },
    { "code": "zu", "name": "Zulu" }
  ],
  fr: [
    { "code": "aa", "name": "Afar" },
    { "code": "ab", "name": "Abkhaze" },
    { "code": "ae", "name": "Avestique" },
    { "code": "af", "name": "Afrikaans" },
    { "code": "ak", "name": "Akan" },
    { "code": "am", "name": "Amharique" },
    { "code": "an", "name": "Aragonais" },
    { "code": "ar", "name": "Arabe" },
    { "code": "as", "name": "Assamais" },
    { "code": "av", "name": "Avar" },
    { "code": "ay", "name": "Aymara" },
    { "code": "az", "name": "Azéri" },
    { "code": "ba", "name": "Bachkir" },
    { "code": "be", "name": "Biélorusse" },
    { "code": "bg", "name": "Bulgare" },
    { "code": "bh", "name": "Langues biharis" },
    { "code": "bi", "name": "Bichlamar" },
    { "code": "bm", "name": "Bambara" },
    { "code": "bn", "name": "Bengali" },
    { "code": "bo", "name": "Tibétain" },
    { "code": "br", "name": "Breton" },
    { "code": "bs", "name": "Bosniaque" },
    { "code": "ca", "name": "Catalan; Valencien" },
    { "code": "ce", "name": "Tchétchène" },
    { "code": "ch", "name": "Chamorro" },
    { "code": "co", "name": "Corse" },
    { "code": "cr", "name": "Cri" },
    { "code": "cs", "name": "Tchèque" },
    { "code": "cu", "name": "Vieux slave; slavon d'église; vieux bulgare; vieux slave d'église" },
    { "code": "cv", "name": "Tchouvache" },
    { "code": "cy", "name": "Gallois" },
    { "code": "da", "name": "Danois" },
    { "code": "de", "name": "Allemand" },
    { "code": "dv", "name": "Maldivien" },
    { "code": "dz", "name": "Dzongkha" },
    { "code": "ee", "name": "Éwé" },
    { "code": "el", "name": "Grec moderne (après 1453)" },
    { "code": "en", "name": "Anglais" },
    { "code": "eo", "name": "Espéranto" },
    { "code": "es", "name": "Espagnol; Castillan" },
    { "code": "et", "name": "Estonien" },
    { "code": "eu", "name": "Basque" },
    { "code": "fa", "name": "Persan" },
    { "code": "ff", "name": "Peul" },
    { "code": "fi", "name": "Finnois" },
    { "code": "fj", "name": "Fidjien" },
    { "code": "fo", "name": "Féroïen" },
    { "code": "fr", "name": "Français" },
    { "code": "fy", "name": "Frison occidental" },
    { "code": "ga", "name": "Irlandais" },
    { "code": "gd", "name": "Gaélique écossais" },
    { "code": "gl", "name": "Galicien" },
    { "code": "gn", "name": "Guarani" },
    { "code": "gu", "name": "Goudjrati" },
    { "code": "gv", "name": "Manx; mannois" },
    { "code": "ha", "name": "Haoussa" },
    { "code": "he", "name": "Hébreu" },
    { "code": "hi", "name": "Hindi" },
    { "code": "ho", "name": "Hiri motu" },
    { "code": "hr", "name": "Croate" },
    { "code": "ht", "name": "Haïtien; créole haïtien" },
    { "code": "hu", "name": "Hongrois" },
    { "code": "hy", "name": "Arménien" },
    { "code": "hz", "name": "Héréro" },
    { "code": "ia", "name": "Interlingua (langue auxiliaire internationale)" },
    { "code": "id", "name": "Indonésien" },
    { "code": "ie", "name": "Occidental; interlingue" },
    { "code": "ig", "name": "Igbo" },
    { "code": "ii", "name": "Yi de Sichuan; Nuosu" },
    { "code": "ik", "name": "Inupiaq" },
    { "code": "io", "name": "Ido" },
    { "code": "is", "name": "Islandais" },
    { "code": "it", "name": "Italien" },
    { "code": "iu", "name": "Inuktitut" },
    { "code": "ja", "name": "Japonais" },
    { "code": "jv", "name": "Javanais" },
    { "code": "ka", "name": "Géorgien" },
    { "code": "kg", "name": "Kikongo" },
    { "code": "ki", "name": "Kikuyu" },
    { "code": "kj", "name": "Kuanyama; kwanyama" },
    { "code": "kk", "name": "Kazakh" },
    { "code": "kl", "name": "Groenlandais" },
    { "code": "km", "name": "Khmer central" },
    { "code": "kn", "name": "Kannada" },
    { "code": "ko", "name": "Coréen" },
    { "code": "kr", "name": "Kanouri" },
    { "code": "ks", "name": "Cachemiri" },
    { "code": "ku", "name": "Kurde" },
    { "code": "kv", "name": "Komi" },
    { "code": "kw", "name": "Cornique" },
    { "code": "ky", "name": "Kirghize" },
    { "code": "la", "name": "Latin" },
    { "code": "lb", "name": "Luxembourgeois" },
    { "code": "lg", "name": "Ganda" },
    { "code": "li", "name": "Limbourgeois" },
    { "code": "ln", "name": "Lingala" },
    { "code": "lo", "name": "Lao" },
    { "code": "lt", "name": "Lituanien" },
    { "code": "lu", "name": "Luba-katanga" },
    { "code": "lv", "name": "Letton" },
    { "code": "mg", "name": "Malgache" },
    { "code": "mh", "name": "Marshall" },
    { "code": "mi", "name": "Maori" },
    { "code": "mk", "name": "Macédonien" },
    { "code": "ml", "name": "Malayalam" },
    { "code": "mn", "name": "Mongol" },
    { "code": "mr", "name": "Marathe" },
    { "code": "ms", "name": "Malais" },
    { "code": "mt", "name": "Maltais" },
    { "code": "my", "name": "Birman" },
    { "code": "na", "name": "Nauruan" },
    { "code": "nb", "name": "Norvégien bokmål" },
    { "code": "nd", "name": "Ndébélé du Nord" },
    { "code": "ne", "name": "Népalais" },
    { "code": "ng", "name": "Ndonga" },
    { "code": "nl", "name": "Néerlandais" },
    { "code": "nn", "name": "Norvégien nynorsk; nynorsk, norvégien" },
    { "code": "no", "name": "Norvégien" },
    { "code": "nr", "name": "Ndébélé du Sud" },
    { "code": "nv", "name": "Navajo" },
    { "code": "ny", "name": "Chewa; chichewa; nyanja" },
    { "code": "oc", "name": "Occitan" },
    { "code": "oj", "name": "Ojibwa" },
    { "code": "om", "name": "Oromo" },
    { "code": "or", "name": "Oriya" },
    { "code": "os", "name": "Ossète" },
    { "code": "pa", "name": "Penjabi" },
    { "code": "pi", "name": "Pali" },
    { "code": "pl", "name": "Polonais" },
    { "code": "ps", "name": "Pachto" },
    { "code": "pt", "name": "Portugais" },
    { "code": "qu", "name": "Quechua" },
    { "code": "rm", "name": "Romanche" },
    { "code": "rn", "name": "Rundi" },
    { "code": "ro", "name": "Roumain" },
    { "code": "ru", "name": "Russe" },
    { "code": "rw", "name": "Kinyarwanda" },
    { "code": "sa", "name": "Sanskrit" },
    { "code": "sc", "name": "Sarde" },
    { "code": "sd", "name": "Sindhi" },
    { "code": "se", "name": "Same du Nord" },
    { "code": "sg", "name": "Sango" },
    { "code": "si", "name": "Cinghalais" },
    { "code": "sk", "name": "Slovaque" },
    { "code": "sl", "name": "Slovène" },
    { "code": "sm", "name": "Samoan" },
    { "code": "sn", "name": "Shona" },
    { "code": "so", "name": "Somali" },
    { "code": "sq", "name": "Albanais" },
    { "code": "sr", "name": "Serbe" },
    { "code": "ss", "name": "Swati" },
    { "code": "st", "name": "Sotho du Sud" },
    { "code": "su", "name": "Soundanais" },
    { "code": "sv", "name": "Suédois" },
    { "code": "sw", "name": "Swahili" },
    { "code": "ta", "name": "Tamoul" },
    { "code": "te", "name": "Télougou" },
    { "code": "tg", "name": "Tadjik" },
    { "code": "th", "name": "Thaï" },
    { "code": "ti", "name": "Tigrigna" },
    { "code": "tk", "name": "Turkmène" },
    { "code": "tl", "name": "Tagalog" },
    { "code": "tn", "name": "Tswana" },
    { "code": "to", "name": "Tongien (Îles Tonga)" },
    { "code": "tr", "name": "Turc" },
    { "code": "ts", "name": "Tsonga" },
    { "code": "tt", "name": "Tatar" },
    { "code": "tw", "name": "Twi" },
    { "code": "ty", "name": "Tahitien" },
    { "code": "ug", "name": "Ouïghour; ouzbek" },
    { "code": "uk", "name": "Ukrainien" },
    { "code": "ur", "name": "Ourdou" },
    { "code": "uz", "name": "Ouzbek" },
    { "code": "ve", "name": "Venda" },
    { "code": "vi", "name": "Vietnamien" },
    { "code": "vo", "name": "Volapük" },
    { "code": "wa", "name": "Wallon" },
    { "code": "wo", "name": "Wolof" },
    { "code": "xh", "name": "Xhosa" },
    { "code": "yi", "name": "Yiddish" },
    { "code": "yo", "name": "Yoruba" },
    { "code": "za", "name": "Zhuang; Chuang" },
    { "code": "zh", "name": "Chinois" },
    { "code": "zu", "name": "Zoulou" }
  ],
  it: [
    { "code": "aa", "name": "Afar" },
    { "code": "ab", "name": "Abcaso" },
    { "code": "ae", "name": "Avestico" },
    { "code": "af", "name": "Afrikaans" },
    { "code": "ak", "name": "Akan" },
    { "code": "am", "name": "Amarico" },
    { "code": "an", "name": "Aragonese" },
    { "code": "ar", "name": "Arabo" },
    { "code": "as", "name": "Assamese" },
    { "code": "av", "name": "Avaric" },
    { "code": "ay", "name": "Aymara" },
    { "code": "az", "name": "Azero" },
    { "code": "ba", "name": "Baschiro" },
    { "code": "be", "name": "Bielorusso" },
    { "code": "bg", "name": "Bulgaro" },
    { "code": "bh", "name": "Bihari" },
    { "code": "bi", "name": "Bislama" },
    { "code": "bm", "name": "Bambara" },
    { "code": "bn", "name": "Bengalese" },
    { "code": "bo", "name": "Tibetano" },
    { "code": "br", "name": "Bretone" },
    { "code": "bs", "name": "Bosniaco" },
    { "code": "ca", "name": "Catalano" },
    { "code": "ce", "name": "Ceceno" },
    { "code": "ch", "name": "Chamorro" },
    { "code": "co", "name": "Corso" },
    { "code": "cr", "name": "Cree" },
    { "code": "cs", "name": "Ceco" },
    { "code": "cu", "name": "Slavo della Chiesa" },
    { "code": "cv", "name": "Chuvash" },
    { "code": "cy", "name": "Gallese" },
    { "code": "da", "name": "Danese" },
    { "code": "de", "name": "Tedesco" },
    { "code": "dv", "name": "Maldiviano" },
    { "code": "dz", "name": "Dzongkha" },
    { "code": "ee", "name": "Ewe" },
    { "code": "el", "name": "Greco" },
    { "code": "en", "name": "Inglese" },
    { "code": "eo", "name": "Esperanto" },
    { "code": "es", "name": "Spagnolo" },
    { "code": "et", "name": "Estone" },
    { "code": "eu", "name": "Basco" },
    { "code": "fa", "name": "Persiano" },
    { "code": "ff", "name": "Fulah" },
    { "code": "fi", "name": "Finlandese" },
    { "code": "fj", "name": "Figiano" },
    { "code": "fo", "name": "Faroese" },
    { "code": "fr", "name": "Francese" },
    { "code": "fy", "name": "Frisone" },
    { "code": "ga", "name": "Irlandese" },
    { "code": "gd", "name": "Gaelico scozzese" },
    { "code": "gl", "name": "Galiziano" },
    { "code": "gn", "name": "Guarani" },
    { "code": "gu", "name": "Gujarati" },
    { "code": "gv", "name": "Manx" },
    { "code": "ha", "name": "Hausa" },
    { "code": "he", "name": "Ebraico" },
    { "code": "hi", "name": "Hindi" },
    { "code": "ho", "name": "Hiri motu" },
    { "code": "hr", "name": "Croato" },
    { "code": "ht", "name": "Haitiano" },
    { "code": "hu", "name": "Ungherese" },
    { "code": "hy", "name": "Armeno" },
    { "code": "hz", "name": "Herero" },
    { "code": "ia", "name": "Interlingua" },
    { "code": "id", "name": "Indonesiano" },
    { "code": "ie", "name": "Interlingue" },
    { "code": "ig", "name": "Igbo" },
    { "code": "ii", "name": "Yi di Sichuan" },
    { "code": "ik", "name": "Inupiak" },
    { "code": "io", "name": "Ido" },
    { "code": "is", "name": "Islandese" },
    { "code": "it", "name": "Italiano" },
    { "code": "iu", "name": "Inuktitut" },
    { "code": "ja", "name": "Giapponese" },
    { "code": "jv", "name": "Giavanese" },
    { "code": "ka", "name": "Georgiano" },
    { "code": "kg", "name": "Kongo" },
    { "code": "ki", "name": "Kikuyu; Gikuyu" },
    { "code": "kj", "name": "Kuanyama; Kwanyama" },
    { "code": "kk", "name": "Kazako" },
    { "code": "kl", "name": "Kalaallisut; Groenlandese" },
    { "code": "km", "name": "Khmer centrale" },
    { "code": "kn", "name": "Kannada" },
    { "code": "ko", "name": "Coreano" },
    { "code": "kr", "name": "Kanuri" },
    { "code": "ks", "name": "Kashmiri" },
    { "code": "ku", "name": "Curdo" },
    { "code": "kv", "name": "Komi" },
    { "code": "kw", "name": "Cornico" },
    { "code": "ky", "name": "Kirghiso" },
    { "code": "la", "name": "Latino" },
    { "code": "lb", "name": "Lussemburghese" },
    { "code": "lg", "name": "Ganda" },
    { "code": "li", "name": "Limburghese" },
    { "code": "ln", "name": "Lingala" },
    { "code": "lo", "name": "Lao" },
    { "code": "lt", "name": "Lituano" },
    { "code": "lu", "name": "Luba-Katanga" },
    { "code": "lv", "name": "Lettone" },
    { "code": "mg", "name": "Malgascio" },
    { "code": "mh", "name": "Marshallese" },
    { "code": "mi", "name": "Maori" },
    { "code": "mk", "name": "Macedone" },
    { "code": "ml", "name": "Malayalam" },
    { "code": "mn", "name": "Mongolo" },
    { "code": "mr", "name": "Marathi" },
    { "code": "ms", "name": "Malese" },
    { "code": "mt", "name": "Maltese" },
    { "code": "my", "name": "Birmano" },
    { "code": "na", "name": "Nauruano" },
    { "code": "nb", "name": "Norvegese Bokmål" },
    { "code": "nd", "name": "Ndebele del Nord" },
    { "code": "ne", "name": "Nepalese" },
    { "code": "ng", "name": "Ndonga" },
    { "code": "nl", "name": "Olandese" },
    { "code": "nn", "name": "Norvegese Nynorsk" },
    { "code": "no", "name": "Norvegese" },
    { "code": "nr", "name": "Ndebele del Sud" },
    { "code": "nv", "name": "Navajo" },
    { "code": "ny", "name": "Nyanja" },
    { "code": "oc", "name": "Occitano" },
    { "code": "oj", "name": "Ojibwa" },
    { "code": "om", "name": "Oromo" },
    { "code": "or", "name": "Oriya" },
    { "code": "os", "name": "Osseto" },
    { "code": "pa", "name": "Panjabi" },
    { "code": "pi", "name": "Pali" },
    { "code": "pl", "name": "Polacco" },
    { "code": "ps", "name": "Pashto" },
    { "code": "pt", "name": "Portoghese" },
    { "code": "qu", "name": "Quechua" },
    { "code": "rm", "name": "Reto-romancio" },
    { "code": "rn", "name": "Rundi" },
    { "code": "ro", "name": "Rumeno" },
    { "code": "ru", "name": "Russo" },
    { "code": "rw", "name": "Kinyarwanda" },
    { "code": "sa", "name": "Sanscrito" },
    { "code": "sc", "name": "Sardo" },
    { "code": "sd", "name": "Sindhi" },
    { "code": "se", "name": "Sami del Nord" },
    { "code": "sg", "name": "Sango" },
    { "code": "si", "name": "Singalese" },
    { "code": "sk", "name": "Slovacco" },
    { "code": "sl", "name": "Sloveno" },
    { "code": "sm", "name": "Samoano" },
    { "code": "sn", "name": "Shona" },
    { "code": "so", "name": "Somalo" },
    { "code": "sq", "name": "Albanese" },
    { "code": "sr", "name": "Serbo" },
    { "code": "ss", "name": "Swati" },
    { "code": "st", "name": "Sotho del Sud" },
    { "code": "su", "name": "Sundanese" },
    { "code": "sv", "name": "Svedese" },
    { "code": "sw", "name": "Swahili" },
    { "code": "ta", "name": "Tamil" },
    { "code": "te", "name": "Telugu" },
    { "code": "tg", "name": "Tagiko" },
    { "code": "th", "name": "Tailandese" },
    { "code": "ti", "name": "Tigrino" },
    { "code": "tk", "name": "Turkmeno" },
    { "code": "tl", "name": "Filippino" },
    { "code": "tn", "name": "Tswana" },
    { "code": "to", "name": "Tonga" },
    { "code": "tr", "name": "Turco" },
    { "code": "ts", "name": "Tsonga" },
    { "code": "tt", "name": "Tataro" },
    { "code": "tw", "name": "Twi" },
    { "code": "ty", "name": "Tahitiano" },
    { "code": "ug", "name": "Uiguro" },
    { "code": "uk", "name": "Ucraino" },
    { "code": "ur", "name": "Urdu" },
    { "code": "uz", "name": "Uzbeco" },
    { "code": "ve", "name": "Venda" },
    { "code": "vi", "name": "Vietnamita" },
    { "code": "vo", "name": "Volapük" },
    { "code": "wa", "name": "Vallone" },
    { "code": "wo", "name": "Wolof" },
    { "code": "xh", "name": "Xhosa" },
    { "code": "yi", "name": "Yiddish" },
    { "code": "yo", "name": "Yoruba" },
    { "code": "za", "name": "Zhuang" },
    { "code": "zh", "name": "Cinese" },
    { "code": "zu", "name": "Zulu" }
  ],
  de: [
    { "code": "aa", "name": "Afar" },
    { "code": "ab", "name": "Abchasisch" },
    { "code": "ae", "name": "Avestisch" },
    { "code": "af", "name": "Afrikaans" },
    { "code": "ak", "name": "Akan" },
    { "code": "am", "name": "Amharisch" },
    { "code": "an", "name": "Aragonesisch" },
    { "code": "ar", "name": "Arabisch" },
    { "code": "as", "name": "Assamesisch" },
    { "code": "av", "name": "Awarisch" },
    { "code": "ay", "name": "Aymara" },
    { "code": "az", "name": "Aserbaidschanisch" },
    { "code": "ba", "name": "Baschkirisch" },
    { "code": "be", "name": "Weißrussisch" },
    { "code": "bg", "name": "Bulgarisch" },
    { "code": "bh", "name": "Bihari-Sprachen" },
    { "code": "bi", "name": "Bislama" },
    { "code": "bm", "name": "Bambara" },
    { "code": "bn", "name": "Bengalisch" },
    { "code": "bo", "name": "Tibetisch" },
    { "code": "br", "name": "Bretonisch" },
    { "code": "bs", "name": "Bosnisch" },
    { "code": "ca", "name": "Katalanisch" },
    { "code": "ce", "name": "Tschetschenisch" },
    { "code": "ch", "name": "Chamorro" },
    { "code": "co", "name": "Korsisch" },
    { "code": "cr", "name": "Cree" },
    { "code": "cs", "name": "Tschechisch" },
    { "code": "cu", "name": "Kirchenslawisch; Altslawisch; Kirchenslawisch; Altbulgarisch; Altkirchenslawisch" },
    { "code": "cv", "name": "Tschuwaschisch" },
    { "code": "cy", "name": "Walisisch" },
    { "code": "da", "name": "Dänisch" },
    { "code": "de", "name": "Deutsch" },
    { "code": "dv", "name": "Dhivehi; Dhivehi; Maledivisch" },
    { "code": "dz", "name": "Dzongkha" },
    { "code": "ee", "name": "Ewe" },
    { "code": "el", "name": "Neugriechisch" },
    { "code": "en", "name": "Englisch" },
    { "code": "eo", "name": "Esperanto" },
    { "code": "es", "name": "Spanisch; Kastilisch" },
    { "code": "et", "name": "Estnisch" },
    { "code": "eu", "name": "Baskisch" },
    { "code": "fa", "name": "Persisch" },
    { "code": "ff", "name": "Ful" },
    { "code": "fi", "name": "Finnisch" },
    { "code": "fj", "name": "Fidschi-Sprache" },
    { "code": "fo", "name": "Färöisch" },
    { "code": "fr", "name": "Französisch" },
    { "code": "fy", "name": "Westfriesisch" },
    { "code": "ga", "name": "Irisch" },
    { "code": "gd", "name": "Gälisch; Schottisch-Gälisch" },
    { "code": "gl", "name": "Galicisch" },
    { "code": "gn", "name": "Guaraní" },
    { "code": "gu", "name": "Gujarati" },
    { "code": "gv", "name": "Manx" },
    { "code": "ha", "name": "Haussa" },
    { "code": "he", "name": "Hebräisch" },
    { "code": "hi", "name": "Hindi" },
    { "code": "ho", "name": "Hiri Motu" },
    { "code": "hr", "name": "Kroatisch" },
    { "code": "ht", "name": "Haitianisch; Haitianisch-Kreolisch" },
    { "code": "hu", "name": "Ungarisch" },
    { "code": "hy", "name": "Armenisch" },
    { "code": "hz", "name": "Herero" },
    { "code": "ia", "name": "Interlingua (International Auxiliary Language Association)" },
    { "code": "id", "name": "Indonesisch" },
    { "code": "ie", "name": "Interlingue; Okzidentalisch" },
    { "code": "ig", "name": "Igbo" },
    { "code": "ii", "name": "Sichuan Yi; Nuosu" },
    { "code": "ik", "name": "Inupiaq" },
    { "code": "io", "name": "Ido" },
    { "code": "is", "name": "Isländisch" },
    { "code": "it", "name": "Italienisch" },
    { "code": "iu", "name": "Inuktitut" },
    { "code": "ja", "name": "Japanisch" },
    { "code": "jv", "name": "Javanisch" },
    { "code": "ka", "name": "Georgisch" },
    { "code": "kg", "name": "Kikongo" },
    { "code": "ki", "name": "Kikuyu; Gikuyu" },
    { "code": "kj", "name": "Kuanyama; Kwanyama" },
    { "code": "kk", "name": "Kasachisch" },
    { "code": "kl", "name": "Grönländisch" },
    { "code": "km", "name": "Zentral-Khmer" },
    { "code": "kn", "name": "Kannada" },
    { "code": "ko", "name": "Koreanisch" },
    { "code": "kr", "name": "Kanuri" },
    { "code": "ks", "name": "Kaschmiri" },
    { "code": "ku", "name": "Kurdisch" },
    { "code": "kv", "name": "Komi" },
    { "code": "kw", "name": "Kornisch" },
    { "code": "ky", "name": "Kirgisisch" },
    { "code": "la", "name": "Lateinisch" },
    { "code": "lb", "name": "Luxemburgisch" },
    { "code": "lg", "name": "Ganda" },
    { "code": "li", "name": "Limburgisch" },
    { "code": "ln", "name": "Lingala" },
    { "code": "lo", "name": "Laotisch" },
    { "code": "lt", "name": "Litauisch" },
    { "code": "lu", "name": "Luba-Katanga" },
    { "code": "lv", "name": "Lettisch" },
    { "code": "mg", "name": "Madagassisch" },
    { "code": "mh", "name": "Marshallesisch" },
    { "code": "mi", "name": "Maorisch" },
    { "code": "mk", "name": "Mazedonisch" },
    { "code": "ml", "name": "Malayalam" },
    { "code": "mn", "name": "Mongolisch" },
    { "code": "mr", "name": "Marathi" },
    { "code": "ms", "name": "Malaiisch" },
    { "code": "mt", "name": "Maltesisch" },
    { "code": "my", "name": "Burmesisch" },
    { "code": "na", "name": "Nauruisch" },
    { "code": "nb", "name": "Bokmål, Norwegisch; Norwegisch Bokmål" },
    { "code": "nd", "name": "Ndebele, Nord; Nord-Ndebele" },
    { "code": "ne", "name": "Nepali" },
    { "code": "ng", "name": "Ndonga" },
    { "code": "nl", "name": "Niederländisch; Flämisch" },
    { "code": "nn", "name": "Norwegisch Nynorsk; Nynorsk, Norwegisch" },
    { "code": "no", "name": "Norwegisch" },
    { "code": "nr", "name": "Ndebele, Süd; Süd-Ndebele" },
    { "code": "nv", "name": "Navajo; Navaho" },
    { "code": "ny", "name": "Chichewa; Chewa; Nyanja" },
    { "code": "oc", "name": "Okzitanisch (nach 1500)" },
    { "code": "oj", "name": "Ojibwa" },
    { "code": "om", "name": "Oromo" },
    { "code": "or", "name": "Oriya" },
    { "code": "os", "name": "Ossetisch" },
    { "code": "pa", "name": "Panjabi; Punjabi" },
    { "code": "pi", "name": "Pali" },
    { "code": "pl", "name": "Polnisch" },
    { "code": "ps", "name": "Paschtu; Paschtu" },
    { "code": "pt", "name": "Portugiesisch" },
    { "code": "qu", "name": "Quechua" },
    { "code": "rm", "name": "Rätoromanisch" },
    { "code": "rn", "name": "Rundi" },
    { "code": "ro", "name": "Rumänisch; Moldauisch; Moldauisch" },
    { "code": "ru", "name": "Russisch" },
    { "code": "rw", "name": "Kinyarwanda" },
    { "code": "sa", "name": "Sanskrit" },
    { "code": "sc", "name": "Sardisch" },
    { "code": "sd", "name": "Sindhi" },
    { "code": "se", "name": "Nordsamisch" },
    { "code": "sg", "name": "Sango" },
    { "code": "si", "name": "Singhalesisch" },
    { "code": "sk", "name": "Slowakisch" },
    { "code": "sl", "name": "Slowenisch" },
    { "code": "sm", "name": "Samoanisch" },
    { "code": "sn", "name": "Shona" },
    { "code": "so", "name": "Somali" },
    { "code": "sq", "name": "Albanisch" },
    { "code": "sr", "name": "Serbisch" },
    { "code": "ss", "name": "Swasi" },
    { "code": "st", "name": "Süd-Sotho" },
    { "code": "su", "name": "Sundanesisch" },
    { "code": "sv", "name": "Schwedisch" },
    { "code": "sw", "name": "Suaheli" },
    { "code": "ta", "name": "Tamil" },
    { "code": "te", "name": "Telugu" },
    { "code": "tg", "name": "Tadschikisch" },
    { "code": "th", "name": "Thailändisch" },
    { "code": "ti", "name": "Tigrinya" },
    { "code": "tk", "name": "Turkmenisch" },
    { "code": "tl", "name": "Tagalog" },
    { "code": "tn", "name": "Setswana" },
    { "code": "to", "name": "Tonga (Tonga-Inseln)" },
    { "code": "tr", "name": "Türkisch" },
    { "code": "ts", "name": "Tsonga" },
    { "code": "tt", "name": "Tatarisch" },
    { "code": "tw", "name": "Twi" },
    { "code": "ty", "name": "Tahitianisch" },
    { "code": "ug", "name": "Uigurisch; Uighur" },
    { "code": "uk", "name": "Ukrainisch" },
    { "code": "ur", "name": "Urdu" },
    { "code": "uz", "name": "Usbekisch" },
    { "code": "ve", "name": "Venda" },
    { "code": "vi", "name": "Vietnamesisch" },
    { "code": "vo", "name": "Volapük" },
    { "code": "wa", "name": "Wallonisch" },
    { "code": "wo", "name": "Wolof" },
    { "code": "xh", "name": "Xhosa" },
    { "code": "yi", "name": "Jiddisch" },
    { "code": "yo", "name": "Yoruba" },
    { "code": "za", "name": "Zhuang; Chuang" },
    { "code": "zh", "name": "Chinesisch" },
    { "code": "zu", "name": "Zulu" }
  ],
};