import styles from './App.scss';
import { useTranslation } from "react-i18next";
import IconSmall from '../../ui/IconSmall';
import TitleUp from '../../ui/TitleUp';
import Description from '../../ui/Description';
import { theme } from 'antd';

// Destructure button prop, with a default value of true
const App = () => {
  const { t, i18n } = useTranslation("global");

  return (
    <section id='app' className='width-100 app-wrapper flex white-bg'>
      <div className='constrain-width padding-sides'>
        <div className='app-grid'>
          <div className='app-title'>
            <TitleUp text={t("member.app.title")} theme='black' />
          </div>
          <div className='app-description'>
            <Description text={t("member.app.description")} theme='black' />
          </div>
          <div className='app-icons_grid'>
            <div className='app-icon_1'>
              <IconSmall origin='app' title={t("member.app.icon-1.title")} description={t("member.app.icon-1.description")} icon="smartphone.svg" alignement="center" theme="dark" size={true} />
            </div>
            <div className='app-icon_2'>
              <IconSmall origin='app' title={t("member.app.icon-2.title")} description={t("member.app.icon-2.description")} icon="star.svg" alignement="center" theme="dark" size={true} />
            </div>
            <div className='app-icon_3'>
              <IconSmall origin='app' title={t("member.app.icon-3.title")} description={t("member.app.icon-3.description")} icon="notif.svg" alignement="center" theme="dark" size={true} />
            </div>
            <div className='app-icon_4'>
              <IconSmall origin='app' title={t("member.app.icon-4.title")} description={t("member.app.icon-4.description")} icon="job.svg" alignement="center" theme="dark" size={true} />
            </div>
            <div className='app-icon_5'>
              <IconSmall origin='app' title={t("member.app.icon-5.title")} description={t("member.app.icon-5.description")} icon="agenda.svg" alignement="center" theme="dark" size={true} />
            </div>
            <div className='app-icon_6'>
              <IconSmall origin='app' title={t("member.app.icon-6.title")} description={t("member.app.icon-6.description")} icon="history.svg" alignement="center" theme="dark" size={true} />
            </div>
          </div>
          <div className='QR-container'>
            <a href='https://apps.apple.com/es/app/be-my-guest-connect/id6636480200' target='blank'>
            <button className='app-QR flex-column'>
              <img src='img/QR_ios.svg'  alt={t("meta.img.downloadApp")}  title={t("meta.img.downloadApp")}></img>
              <img className='icon-store' src='img/logo_appleStore.svg'  alt={t("meta.img.downloadApp")}  title={t("meta.img.downloadApp")}></img>
            </button>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.bmgbrainers.bemyguest' target='blank'>
            <button className='app-QR flex-column'>
              <img src='img/QR_android.svg'  alt={t("meta.img.downloadApp")}  title={t("meta.img.downloadApp")}></img>
              <img className='icon-store' src='img/logo_googlePlay.svg'   alt={t("meta.img.downloadApp")}  title={t("meta.img.downloadApp")}></img>
            </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default App;