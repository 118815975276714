import React, { useState } from 'react';
import './RadioButton.scss';

const RadioButton = ({ value, name, checked, onChange }) => {
    return (
        <label className={`radio-container ${checked ? 'checked' : ''}`}>
            {value}
            <input
                type="checkbox" // Use checkbox for multiple selection capability
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <span className="custom-radio"></span>
        </label>
    );
};

export default RadioButton;