
import styles from './Footer.scss'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const Footer = () => {
    const [t, i18n] = useTranslation("global")
    

    const Social = (props) => {
        return (
          <li>
            <button className="header-menu_link"
              onClick={() => window.open(props.link, '_blank')}
            >
              <img className='icon-social footer-social_item' src={'img/icons/' + props.icon + '.svg'} alt={'Be My Guest '+props.alt} title={'Be My Guest '+props.alt}></img>
            </button>
          </li>
        );
      };

    return (
        <footer className='width-100 flex accent-bg'>
            <div className='constrain-width footer-wrapper'>
                <div className='footer-grid'>
                    <div className='footer-adress'>
                        <img className='footer-logo' src='./img/logo_dark_notagline_rgb.svg'  alt='Be My Guest' title='Be My Guest' />
                        <h5 className='text-dark'>BE MY GUEST SARL<br></br>CHE-114.565.243 TVA.<br></br>Administrative Center<br></br>2016 Cortaillod.</h5>
                    </div>
                    <div className='footer-baseline'>
                      <div className='footer-legal'>
                      <h5 className='text-dark text-center'>
                        {t("footer.copyright")}
                        <span>
                          <NavLink to='/legal' className='text-dark'>{t("footer.legal")}</NavLink>
                        </span> - 
                        <span>
                          <NavLink to='/privacy' className='text-dark'>{t("footer.privacy")}</NavLink>
                        </span> - 
                        <span>
                          <NavLink to='/support' className='text-dark'>{t("footer.faq")}</NavLink>
                        </span>
                      </h5>
                      </div>
                      <ul className='footer-social'>

                          <Social link="https://www.instagram.com/bemyguestsarl" icon="icon_insta_b" alt='Instagram' />
                          <Social link="https://twitter.com/BeMyGuestSarl" icon="icon_X_b"  alt='Twitter / X'/>
                          <Social link="https://www.facebook.com/Bemyguestsarl" icon="icon_fb_b"  alt='Facebook'/>
                          <Social link="https://www.linkedin.com/company/bemyguest-sarl/?viewAsMember=true" icon="icon_in_b"  alt='LinkedIn'/>
                          <Social link="mailto:info@bemyguest.ch" icon="icon_mail_b" alt='Contact' />
                          <div className='footer-phone'>
                            <Social link="tel:+41789441279" icon="icon_phone_b" alt='Contact'/>
                            <h5 className='text-dark'>00 41 78 944 12 79</h5>
                          </div>

                      </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer