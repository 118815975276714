import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './TextField.scss';

const TextField = ({ value, defaultValue, required = true, type = 'text', title, description, placeHolder = '' }) => {
    const { t, i18n } = useTranslation("global");
    const { register, formState: { errors } } = useFormContext();

    // If no description is passed, use the translation by default
    const defaultDescription = description || t("client.form.field-default");

    // Validation rules
    const validationRules = {
        required: required,
        setValueAs: (v) => (type === 'number' ? parseFloat(v) : v),
        ...(type === 'number' && { min: 1 }) // Add min validation for number type
    };

    return (
        <div className='text-field'>
            <label htmlFor={value}>{title}</label>
            {/* Register the input field with React Hook Form */}
            <input
                type={type}
                id={value}
                placeholder={placeHolder}
                {...register(value, validationRules)}
                defaultValue={type === 'number' ? (defaultValue ?? 1) : defaultValue} // Default to 1 for number type
                min={type === 'number' ? 1 : undefined} // Set the minimum value for number type
            />
            {/* Display validation message based on form state */}
            {errors[value]?.type === 'required' ? (
                <h5 style={{ color: 'red' }}>{t("client.form.field-error")}</h5>
            ) : errors[value]?.type === 'min' ? (
                <h5 style={{ color: 'red' }}>{t("client.form.field-numberError")}</h5>
            ) : (
                <h5>{defaultDescription}</h5>
            )}
        </div>
    );
};

export default TextField;