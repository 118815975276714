import styles from './Client.scss'
import { useTranslation } from "react-i18next";

import IconSmall from '../../ui/IconSmall';
import TitleUp from '../../ui/TitleUp';
import Description from '../../ui/Description';
import Button from '../../ui/Button';

const Client = () => {
  const [t, i18n] = useTranslation("global")

    return (
        <section className='width-100 client-wrapper flex white-bg'>
          
        <div className='constrain-width padding-sides'>
          
          <div className='client-grid'>
            <div className='client-title'>
              <TitleUp text={t("client.title")} theme='black' />
            </div>
            <div className='client-description'>
              <Description text={t("client.description")} theme='black' />
              <div className='client-button'>
              <Button theme="dark" title={t("client.button")} to="/client" />
              </div>
            </div>
            
            <div className='client-icons'>
                
                <ul className='client-list'>

                <IconSmall title={t("client.icon-1.title")} description={t("client.icon-1.description")} icon="star.svg" alignement="center" theme="dark" size={true} />
                <IconSmall title={t("client.icon-2.title")} description={t("client.icon-2.description")} icon="recycle.svg" alignement="center" theme="dark" size={true} />
                <IconSmall title={t("client.icon-3.title")} description={t("client.icon-3.description")} icon="phone.svg" alignement="center" theme="dark" size={true} />

                </ul>

            </div>
            
          </div>
        </div>

      </section>
    )
}

export default Client;