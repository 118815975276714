import styles from './Logo.scss';

import React, { useEffect, useRef } from 'react';

import { gsap } from "gsap";


const Logo = () => {
    const logoRef = useRef(null);
    const loaderRef = useRef(null);

    useEffect(() => {

        gsap.to(loaderRef.current, {
            strokeDasharray: '624 624',
            duration: 1,
            delay: 0,
            ease: 'Quad.easeInOut',
    
            onComplete: () => {
                gsap.to(loaderRef.current, {
                    opacity: 0,
                    duration: 1,
                    ease: 'Quad.easeIn',
                })
                gsap.to(logoRef.current, {
                    opacity: 1,
                    duration: 1,
                    ease: 'Quad.easeOut',
                })
            }
        });

    }, [])

    return (

        <div className='logo-wrapper'>
            <img className='logo' ref={logoRef} src='img/logo_light_rgb.svg' alt='Be My Guest' title='Be My Guest'></img>
            <svg className='logo-loader' x={0} y={0} width={200} height={126}>
                <path id="rectangle" ref={loaderRef} className="logo-loader__rect" d="M 3.5 3.5 L 196.5 3.5 L 196.5 122.5 L 3.5 122.5 z"/>
            </svg>
        </div>
            
    )
};
export default Logo