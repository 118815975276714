import React, { useState, useEffect } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useTranslation } from "react-i18next";
import styles from './HoursRange.scss';

const HoursRange = ({ firstHourInput, setFirstHourInput, lastHourInput, setLastHourInput }) => {
    const { t, i18n } = useTranslation("global");


    // State to hold the slider value
    const [schedule, setSchedule] = useState([firstHourInput, lastHourInput]);

    const handleScheduleChange = (value) => {
        //console.log(value)
        setSchedule(value);
        setFirstHourInput(value[0]);
        setLastHourInput(value[1]);
    };

    const [firstPeriod, setFirstPeriod] = useState(firstHourInput);

    const handleFirstHourInputChange = (event) => {
        let inputValue = parseInt(event.target.value, 10);

        if (isNaN(inputValue)) inputValue = 0;
  
        setFirstHourInput(inputValue);
        setSchedule([inputValue, schedule[1]]);
    };

    const [lastPeriod, setLastPeriod] = useState(lastHourInput);

    const handleLastHourInputChange = (event) => {
        let inputValue = parseInt(event.target.value, 10);
        if (isNaN(inputValue)) inputValue = 0;

        setLastHourInput(inputValue);
        setSchedule([schedule[0], inputValue]);
    };


    return (
        <div className='hours-range'>
            <div className='form-title'>
                <label>{t("client.form.event.hours")}</label>
                <div className='range-circle'>
                    <input
                        type="text"
                        inputMode="numeric"
                        min="6"
                        max="24"
                        value={firstHourInput}
                        onChange={handleFirstHourInputChange}
                    />
                </div>
                <h5 className='range-text'>{t("client.form.event.from")}</h5>
                <div className='range-circle'>
                    <input
                        type="text"
                        inputMode="numeric"
                        min="6"
                        max="24"
                        value={lastHourInput}
                        onChange={handleLastHourInputChange}
                    />
                </div>
                <h5>{t("client.form.event.to")}</h5>
            </div>
            <div className='form-slider'>
                <RangeSlider
                    id="range-slider-yellow"
                    min={6}
                    max={24}
                    defaultValue={schedule}
                    value={schedule}
                    onInput={handleScheduleChange}
                />
            </div>
            <h5>{t("client.form.event.hours-description")}</h5>
        </div>
    );
};

export default HoursRange;