import styles from './FAQ.scss';
import { useTranslation } from "react-i18next";
import IconSmall from '../../ui/IconSmall';
import TitleUp from '../../ui/TitleUp';
import TitleReveal from '../../ui/TitleReveal';
import Description from '../../ui/Description';
import Phone from '../Phone';

// Destructure button prop, with a default value of true
const FAQ = () => {
  const { t, i18n } = useTranslation("global");

  return (
    <section className='width-100 FAQ-wrapper flex white-bg'>
      <div className='constrain-width padding-sides'>
        <div className='FAQ-grid'>
          <Phone className='FAQ-image' />
          <div className='FAQ-content'>
            <div className='FAQ-title'>
              <TitleUp text={t("FAQ.title")} theme='black' />
            </div>
            <ul className='service-icons flex-column card-list FAQ-list'>
              <IconSmall title={t("FAQ.icon-1.title")} description={t("FAQ.icon-1.description")} icon="smartphone.svg" alignement="left" theme="dark" />
              <IconSmall title={t("FAQ.icon-2.title")} description={t("FAQ.icon-2.description")} icon="profile.svg" alignement="left" theme="dark" />
              <IconSmall title={t("FAQ.icon-3.title")} description={t("FAQ.icon-3.description")} icon="validation.svg" alignement="left" theme="dark" />
              <IconSmall title={t("FAQ.icon-4.title")} description={t("FAQ.icon-4.description")} icon="job.svg" alignement="left" theme="dark" />
            </ul>
          </div>
          </div>
      </div>
    </section>
  );
};

export default FAQ;