import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import styles from './Brand.scss'
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
//import logos from "./logos";

const Brand = () => {

    const { t } = useTranslation("global");

    const array = [0,1]; // Double the logo depending on the array.length

    const slider = useRef(null)

    const logos = ['cartier', 'vc', 'vca', 'a-lange', 'aurora', 'bilan', 'wef', 'vaudoisenb', 'nissan', 'bmwnb', 'infiniti', 'byd', 'tcs', 'mazda', 'moinet', 'seiko', 'prada', 'guerlain', 'tamedia', 'golden_goose', 'rolex', 'franck-muller', 'bnp', 'moet', 'rothschild', 'bulgari', 'vaucher', 'piaget', 'pictet', 'dubuis', 'tesla', 'bcge'];

    const brands = {
      'cartier' : 'Cartier', 
      'vc' : 'Vacheron Constantin - Genève', 
      'vca' : 'Van Cleef & Arpels', 
      'a-lange' : 'A.Lange & Sohne', 
      'aurora' : 'Aurora Aviation', 
      'bilan' : 'Bilan', 
      'wef' : 'World Economic Forum', 
      'vaudoisenb' : 'Vaudoise', 
      'nissan' : 'Nissan', 
      'bmwnb' : 'BMW', 
      'infiniti' : 'Infiniti', 
      'byd' : 'BYD', 
      'tcs' : 'TCS', 
      'mazda' : 'Mazda', 
      'moinet' : 'Louis Moinet', 
      'seiko' : 'Seiko', 
      'prada' : 'Prada', 
      'guerlain' : 'Guerlain', 
      'tamedia' : 'Tamedia', 
      'golden_goose' : 'Golden Goose', 
      'rolex' : 'Rolex', 
      'franck-muller' : 'Franck Muller', 
      'bnp' : 'BNP Paribas', 
      'moet' : 'Moet & Chandon', 
      'rothschild' : 'Edmond de Rothschild', 
      'bulgari' : 'Bulgari', 
      'vaucher' : 'Vaucher', 
      'piaget' : 'Piaget', 
      'pictet' : 'Pictet', 
      'dubuis' : 'Roger Dubuis - Horloger Genève', 
      'tesla' : 'Tesla', 
      'bcge' : 'BCGE'      
    };

    const [inview, setInview] = useState(false);

    const { ref, inView } = useInView({
      //triggerOnce: true,
      threshold: 0
    });

    

    useEffect(() => {
      if (inView) {
        setInview(true);
      } else {
        setInview(false);
      }
    }, [inView]);

    useEffect(() => {
        if(inView) {
          gsap.to(slider.current, {
            xPercent: -50,
            duration: 60,
            repeat: -1,
            ease: 'linear'
          });
        }else {
          gsap.set(slider.current, {xPercent: 0})
          gsap.killTweensOf(slider.current);
        }
    }, [inView]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        if(mediaQuery.matches) {
            //console.log("Brand will not be animated")
        } else {
            //console.log("Brand is animated")
        }
        mediaQuery.addEventListener('change', () => {
            //console.log("BRAND MediaQuery" + mediaQuery.media, mediaQuery.matches);
        // Stop JavaScript-based animations.
        });

    }, [])


    return (
      <section className='width-100 brand-wrapper' ref={ref}>
      <div className='scroller'>
        <ul className='brand-list scroller-inner' ref={slider}>
          {array.map((index) => {
            return (
              <div key={`brand-flex-${index}`} className='brand-flex'>
                {logos.map((logo, idx) => {
                  // Utiliser une clé combinée unique pour chaque image
                  return (
                    <div key={`logo-${index}-${idx}`}>
                      <img className='brand-item' src={`img/brands/${logo}.png`} title={'Be My Guest '+brands[logo]}  alt={'Be My Guest '+brands[logo]} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </ul>
      </div>
    </section>
    )
}

export default Brand;