import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";

import './TextSelect.scss';

const TextSelect = ({ value, defaultValue, title, description, JSON }) => {
    const [t, i18n] = useTranslation("global");
    // Use useFormContext to access methods from React Hook Form
    const { register, formState: { errors } } = useFormContext();

    return (
        <div className='form-select'>
            <label htmlFor={value} className='form-dropdown'>{title}
            <select id={value} {...register(value, { required: false })}
            defaultValue={defaultValue} // Set the initial value
            >
                {JSON.map((title, idx) => (
                <option key={idx.toString()} value={title}>{t(title)}</option>
                ))}
            </select>
        </label>
            <h5>{description}</h5>
        </div>
    );
};

export default TextSelect;