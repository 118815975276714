import styles from './Header.scss';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import gsap from 'gsap';

const Header = () => {
  const [t, i18n] = useTranslation("global");
  const headerRef = useRef(null); // Updated ref for the header
  const [isOpen, setIsOpen] = useState(false); // State to manage header visibility
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    
    if (!searchParams.has('lang')) {
      const lang = navigator.language || navigator.userLanguage; // Langue du navigateur
      const langApp = lang.includes('-') ? lang.split('-')[0] : lang;
      i18n.changeLanguage(langApp);
    }
    
  }, []);

  const changeLanguage = (lng) => {
    if (searchParams.has('lang')) {
      searchParams.delete('lang');
      window.history.replaceState(null,'', window.location.pathname );
    }
    i18n.changeLanguage(lng);
  }

  const languages = [
    { code: 'en', label: 'EN' },
    { code: 'fr', label: 'FR' },
    { code: 'de', label: 'DE' },
    { code: 'it', label: 'IT' }
  ];

  const Social = (props) => {
    return (
      <li>
        <button className="header-menu_link"
          onClick={() => window.open(props.link, '_blank')}
        >
          <img className='icon-social' src={'img/icons/' + props.icon + '.svg'} alt={props.link}></img>
        </button>
      </li>
    );
  };

  const location = useLocation();

  useEffect(() => {
    // Only trigger once the fade animation at mount
    gsap.to(headerRef.current, {
      opacity: 1,
      duration: 1,
      delay: 2,
    });
  }, []);

  // Toggle header visibility
  const toggleHeaderVisibility = () => {

    if(!isOpen) {
      openMenu();
      
    } else {
      closeMenu();
    }
  }

  const openMenu = () => {
    gsap.killTweensOf(headerRef.current);
    gsap.set(headerRef.current, {
      y:"-100%",
    });
    gsap.to(headerRef.current, {
      y:"0%",
      duration: 0.6,
      ease: "Quad.easeOut",
    });
    setIsOpen(true);
  }

  const closeMenu = () => {
    gsap.killTweensOf(headerRef.current);
    gsap.to(headerRef.current, {
      y:"-100%",
      duration: 0.3,
      ease: "Quad.easeIn",
    });
    setIsOpen(false);
  }

  const handleClick = (event) => {
    if(isOpen) {
      closeMenu();
    }
  };


  return (
    <>
      {/* Apply the appropriate className based on header visibility */}
      {/* className={`padding-sides header ${isHeaderVisible ? 'visible' : 'hidden'}`} */}
      <header className={`header`}>
        <div className='padding-sides header-content' ref={headerRef}>
          <div className='header-left'>
            <ul className='header-menu'>
              <NavLink className='header-menu_link' to="" onClick={handleClick}>{t("header.homepage")}</NavLink>
              <NavLink className='header-menu_link' to="/member" onClick={handleClick}>{t("header.member")}</NavLink>
              <NavLink className='header-menu_link' to="/client" onClick={handleClick}>{t("header.client")}</NavLink>
              <NavLink className='header-menu_link' to="/gallery" onClick={handleClick}>{t("header.gallery")}</NavLink>
              <NavLink className='header-menu_link' to="/contact" onClick={handleClick}>{t("header.contact")}</NavLink>
            </ul>
          </div>

          <div className='header-right'>
            <ul className='header-social'>
              <Social link="https://www.instagram.com/bemyguestsarl" icon="icon_insta" />
              <Social link="https://twitter.com/BeMyGuestSarl" icon="icon_X" />
              <Social link="https://www.facebook.com/Bemyguestsarl" icon="icon_fb" />
              <Social link="https://www.linkedin.com/company/bemyguest-sarl/?viewAsMember=true" icon="icon_in" />
              <Social link="mailto:info@bemyguest.ch" icon="icon_mail" />
              <p className='text-white'>|</p>
            </ul>
            <ul className='header-lang'>
              {languages.map(({ code, label }) => (
                <li key={code}>
                  {i18n.language === code ? (
                    <span className="header-menu_link active">{label}</span>
                  ) : (
                    <button className="header-menu_link" onClick={() => changeLanguage(code)}>{label}</button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        
      </header>
      {/* Add onClick to toggle header visibility */}
      <button className='header-burger' onClick={toggleHeaderVisibility}>
        <div className='header-burger__circle'></div>
        <div className='header-burger__circle'></div>
        <div className='header-burger__circle'></div>
      </button>
      <div>
        <Outlet />
      </div>
    </>
  );
}

export default Header;