export const serviceArray = ["services.service-1.title",
    "services.service-2.title",
    "services.service-3.title",
    "services.service-4.title",
    "services.service-5.title",
    "services.service-6.title",
    "services.service-7.title",
    "services.service-8.title",
    "services.service-9.title",
    "services.service-10.title",
    "services.service-11.title",
    "services.service-12.title",
    "Other"
];

export const legalHTML = {
    en: {
        privacyHtml : `
<h2>Privacy policy</h2>
<h3>1. Collection of Personal Data</h3>
<p>The Be My Guest Connect application (hereinafter referred to as "the Application") collects and processes personal data in accordance with applicable data protection laws, including the General Data Protection Regulation (GDPR) of the European Union and the Federal Data Protection Act (LPD) in Switzerland. The collected data includes, but is not limited to, name, email address, location information, and any other information provided by the user to use the services of the Application.</p>
<h3>2. Purpose of Data Processing</h3>
<p>The personal data collected via the Application is used for the following purposes:</p>
<p>To provide and improve the services offered by Be My Guest Connect.</p>
<p>To facilitate connections between users.</p>
<p>To manage assistance requests and communications related to the use of the Application.</p>
<p>To comply with legal and regulatory obligations in force in Switzerland and the EU.</p>
<h3>3. Legal Basis for Processing</h3>
<p>The processing of personal data is based on several legal grounds, including:</p>
<p>Contract execution: The processing is necessary for the execution of a contract to which the user is a party (for the provision of the services of the Application).</p>
<p>Consent: Certain specific features require the explicit consent of the user.</p>
<p>Legal obligations: The processing is required to meet the legal obligations to which Be My Guest Connect is subject under Swiss and European data protection laws.</p>
<h3>4. Data Sharing</h3>
<p>User personal data is not sold or rented to third parties. It may be shared with third-party service providers only for the operational needs of the Application, and in compliance with the GDPR and LPD. Each third party is subject to a contractual obligation to respect data confidentiality.</p>
<h3>5. International Data Transfer</h3>
<p>In cases where data is transferred to countries outside the European Economic Area (EEA) or Switzerland, Be My Guest Connect ensures that these transfers are made in accordance with applicable laws, notably through standard contractual clauses or by ensuring that an adequacy decision is in place (as for Switzerland).</p>
<h3>6. Data Retention Period</h3>
<p>Personal data is retained as long as necessary to achieve the objectives described in this policy, unless a longer retention period is required or permitted by Swiss or European law.</p>
<h3>7. User Rights</h3>
<p>In accordance with data protection legislation (GDPR and LPD), every user has the following rights regarding their personal data:</p>
<p>Right of access: You have the right to request a copy of your personal data.</p>
<p>Right of rectification: You can request the correction of any inaccurate or incomplete data.</p>
<p>Right to erasure: You can request the deletion of your personal data in certain circumstances.</p>
<p>Right to data portability: You have the right to receive your data in a structured, commonly used, and machine-readable format.</p>
<p>Right to object: You can object to the processing of your personal data for legitimate reasons.</p>
<p>Right to withdraw consent: You can withdraw your consent at any time when the processing is based on your consent.</p>
<p>For users residing in Switzerland, these rights are guaranteed by the LPD. For those residing in the European Union, these rights are guaranteed by the GDPR. Requests regarding the exercise of these rights can be sent to the following email address: contact@bemyguest.ch.</p>
<h3>8. Data Security</h3>
<p>The Application implements appropriate technical and organizational security measures to protect personal data against loss, unauthorized use, access, disclosure, or modification. These measures comply with the requirements of the GDPR and LPD. However, no method of transmission or data storage is completely secure, and we cannot guarantee absolute security.</p>
<h3>9. Changes to the Privacy Policy</h3>
<p>This privacy policy may be modified at any time. Any changes will be published on the Application, and users will be informed of significant changes by email or through a notification in the Application. It is recommended to regularly check this page for any updates.</p>
<h3>10. Contact</h3>
<p>For any questions regarding this privacy policy or the management of your personal data, you can contact us at the following address: Administrative Center, Chemin des Polonais 16A, CH – 2016 Cortaillod.</p>
`,

cguHtml : `
<h2>Legal mentions</h2>
<h3>1. Preamble</h3>
<p>The present General Terms of Use (hereinafter referred to as "GTU") govern the use of the Be My Guest Connect mobile application (hereinafter referred to as "the Application"), published by [Be My Guest Sàrl]. By downloading, installing, or using the Application, the user (hereinafter referred to as "the User") unreservedly accepts these GTU.</p>
<h3>2. Purpose of the Application</h3>
<p>Be My Guest Connect aims to connect hosts directly with brands to manage their reception in the context of professional events. The Application is available on iOS and Android operating systems.</p>
<h3>3. Access to the Application</h3>
<p>Access to the Application is free. However, to use certain features, it is necessary to create a user account. The User must have reached the legal age to consent to the use of their data according to applicable local laws and ensure that they use the Application lawfully.</p>
<h3>4. Account Creation</h3>
<p>To access certain features, the User must create an account by providing accurate and complete personal information. The User agrees to:</p>
<p>· Provide truthful information during registration.</p>
<p>· Maintain the confidentiality of their username and password.</p>
<p>· Immediately inform the publisher in case of unauthorized use of their account.</p>
<p>The publisher reserves the right to suspend or delete an account in the event of non-compliance with these GTU or fraudulent use.</p>
<h3>5. Use of the Application</h3>
<p>The User agrees to use the Application in compliance with applicable laws and not to:</p>
<p>· Use the Application for illegal, fraudulent, or malicious purposes.</p>
<p>· Disseminate offensive, defamatory, discriminatory content, or infringing on the rights of third parties.</p>
<p>· Disrupt the proper functioning of the Application, particularly through hacking actions.</p>
<p>The publisher reserves the right to remove any content or block any user who does not comply with these rules.</p>
<h3>6. Responsibilities of the Publisher</h3>
<p>The publisher commits to implementing all reasonable means to ensure continuous and secure access to the Application. However, access to the Application may be temporarily suspended for maintenance or technical updates.</p>
<p>The publisher cannot be held liable for:</p>
<p>· Temporary interruptions of the Application for maintenance or updates.</p>
<p>· Indirect damages, loss of profits, or loss of data resulting from the use of the Application.</p>
<p>· User-generated content.</p>
<h3>7. Personal Data</h3>
<p>The use of the Application involves the collection and processing of personal data in accordance with our Privacy Policy, which complies with the General Data Protection Regulation (GDPR) as well as the Federal Data Protection Act (DPA) in Switzerland. By using the Application, the User consents to the processing of their personal data in accordance with this policy.</p>
<h3>8. Intellectual Property</h3>
<p>All content available on the Application, such as texts, graphics, logos, images, sounds, and software, are the exclusive property of Be My Guest or/and its partners. Any reproduction, modification, distribution, or use, in whole or in part, of this content without prior authorization is strictly prohibited and constitutes an infringement punishable by applicable laws.</p>
<h3>9. Hyperlinks</h3>
<p>The Application may contain links to third-party websites. The publisher cannot be held responsible for the content, offers, or practices of these third-party sites. The user accesses these sites at their own risk.</p>
<h3>10. Modification of the GTU</h3>
<p>The publisher reserves the right to modify these General Terms of Use at any time. Changes will take effect as soon as they are published on the Application. The User will be informed of significant changes by notification in the Application or by email. It is recommended to regularly consult the GTU to be aware of any updates. Continued use of the Application after modifications constitutes acceptance of the new GTU.</p>
<h3>11. Applicable Law and Jurisdiction</h3>
<p>These GTU are governed by Swiss law. In case of a dispute, the parties agree to seek an amicable solution. Failing this, any dispute will be submitted to the competent courts of Neuchâtel, Switzerland.</p>
<h3>12. General Provisions</h3>
<p>If any provision of these GTU is declared null or unenforceable by a competent court, this will not affect the validity of the other provisions, which will remain in force. These GTU constitute the entire agreement between the User and the publisher regarding the use of the Application and replace all previous agreements.</p>
<h3>13. Contact</h3>
<p>For any questions regarding these General Terms of Use or the use of the Application, the User can contact the publisher at the following address: contact@bemyguest.ch or by mail: Be My Guest, Administrative Center, Chemin des Polonais 16A, CH – 2016 Cortaillod.</p>
`,

faqHtml : `
<h2>FAQ & Support</h2>
<h3>1. General Information</h3>

<p class='annexe-question' >What is this app designed for?</p>
<p>This app is designed for Hosts & Hostesses to manage missions, communicate via chat, and access basic user information relevant to their assignments.</p>

<p class='annexe-question'>Who can use this app?</p>
<p>The app is for registered Hosts & Hostesses and approved users who require assistance coordinating events or missions.</p>

<p class='annexe-question'>How do I download the app?</p>
<p>You can download the app from the App Store for iOS or Google Play Store for Android devices.</p>

<h3>2. Account and Profile</h3>

<p class='annexe-question'>How do I create an account?</p>
<p>To create an account, download the app and follow the on-screen registration process. You will need to enter some basic information and verify your email.</p>

<p class='annexe-question'>How can I update my personal information?</p>
<p>To update your information, go to the “Profile” tab and make the necessary changes. Ensure your contact details are up to date to receive important notifications and up to date missions.</p>

<p class='annexe-question'>How do I reset my password?</p>
<p>On the login screen, select “Forgot Password” and follow the steps to reset it. You will receive a link via email to create a new password.</p>

<p class='annexe-question'>How is my personal data used and protected?</p>
<p>We prioritize your privacy. Your data is stored securely and used solely for purposes related to your assignments and communication on the app. For more details, see our Privacy Policy below.</p>

<h3>3. Missions and Assignments</h3>

<p class='annexe-question'>How do I receive mission notifications?</p>
<p>When a new mission is available, you’ll receive a push notification. Ensure that you have notifications enabled in both the app settings and your device settings.</p>

<p class='annexe-question'>How do I accept a mission?</p>
<p>Go to the “Missions” tab, review the details of an available mission, and select “Postulate Mission.” Once accepted, the mission will be added to your list of active missions.</p>

<p class='annexe-question'>How do I complete a mission?</p>
<p>Once you’ve finished all required tasks for a mission, go to your active mission list, select the completed mission.</p>

<p class='annexe-question'>How do I view completed missions?</p>
<p>To view past missions, go to the “Completed” tab under the Missions section. Here, you can access details of all completed assignments.</p>

<p class='annexe-question'>What should I do if I can’t complete a mission?</p>
<p>If you’re unable to complete a mission, inform your coordinator as soon as possible using the in-app chat.</p>

<h3>4. Chat Feature</h3>

<p class='annexe-question'>How do I start a chat with a coordinator?</p>
<p>Select the “Chat” tab to start a conversation. Type your message and send it.</p>

<p class='annexe-question'>Can I chat with other hosts or hostesses?</p>
<p>The chat is only available with coordinators associated with your active missions.</p>

<p class='annexe-question'>How do I know if I have a new message?</p>
<p>You will receive a push notification for any new messages. Make sure notifications are enabled for the app in your device settings.</p>

<p class='annexe-question'>What should I do if my chat isn’t working?</p>
<p>Check your internet connection. If the issue persists, try logging out and logging back in or restarting the app. If the problem continues, contact our support.</p>

<h3>5. Notifications and Reminders</h3>

<p class='annexe-question'>How can I manage my notifications?</p>
<p>Go to “Settings” in the app to control notification preferences. You can enable or disable notifications for Be My Guest.</p>

<p class='annexe-question'>Why am I not receiving notifications?</p>
<p>Ensure that notifications are enabled both in the app settings and on your device. If the issue persists, try logging out and back in or reinstalling the app.</p>

<h3>6. Troubleshooting</h3>

<p class='annexe-question'>The app is slow or not loading—what should I do?</p>
<p>Check your internet connection first. If the issue continues, try restarting the app or your device. If the problem persists, update the app to the latest version or contact support.</p>

<p class='annexe-question'>I’m having trouble logging in. What should I do?</p>
<p>Ensure your login credentials are correct. If you’ve forgotten your password, use the “Forgot Password” option. For other login issues, reach out to support.</p>

<p class='annexe-question'>How do I contact support?</p>
<p>For help, you can contact us through the "Chat" tab or on our website.</p>

<h3>7. Privacy and Security</h3>

<p class='annexe-question'>Is my information secure?</p>
<p>Yes, we use encryption and follow best practices to ensure your data is secure. Only authorized parties can access your information as required for the app’s functionality.</p>

<p class='annexe-question'>Can I delete my account?</p>
<p>To request account deletion, go to “Settings” > “Delete Account.” This will permanently remove your data from our servers.</p>

<h3>8. Additional Features</h3>

<p class='annexe-question'>Can I customize my profile picture?</p>
<p>Yes, go to “Profile” > "Medias" and tap on your photo to change it. You can upload a new picture from your gallery or take a photo directly through the app.</p>

<p class='annexe-question'>Does the app work offline?</p>
<p>No, for real-time updates, chats, and notifications, you need an internet connection.</p>
`
    },
    fr : {
        privacyHtml : `
<h2>Politique de confidentialité</h2>
<h3>1. Collecte de Données Personnelles</h3>
<p>L'application Be My Guest Connect (ci-après dénommée « l'Application ») collecte et traite des données personnelles conformément aux lois applicables sur la protection des données, notamment le Règlement Général sur la Protection des Données (RGPD) de l'Union Européenne et la Loi Fédérale sur la Protection des Données (LPD) en Suisse. Les données collectées incluent, sans s'y limiter, le nom, l'adresse e-mail, les informations de localisation, ainsi que toute autre information fournie par l'utilisateur pour utiliser les services de l'Application.</p>
<h3>2. Finalité du Traitement des Données</h3>
<p>Les données personnelles collectées via l'Application sont utilisées dans le cadre des finalités suivantes :</p>
<p>Fournir et améliorer les services offerts par Be My Guest Connect.</p>
<p>Faciliter la mise en relation entre les utilisateurs.</p>
<p>Gérer les demandes d'assistance et les communications liées à l'utilisation de l'Application.</p>
<p>Se conformer aux obligations légales et réglementaires en vigueur en Suisse et dans l'UE.</p>
<h3>3. Base Légale du Traitement</h3>
<p>Le traitement des données personnelles repose sur plusieurs bases légales, notamment :</p>
<p>Exécution du contrat : Le traitement est nécessaire à l'exécution d'un contrat auquel l'utilisateur est partie (pour la fourniture des services de l'Application).</p>
<p>Consentement : Certaines fonctionnalités spécifiques nécessitent le consentement explicite de l'utilisateur.</p>
<p>Obligations légales : Le traitement est requis pour répondre aux obligations légales auxquelles Be My Guest Connect est soumis, en vertu des lois suisses et européennes sur la protection des données.</p>
<h3>4. Partage des Données</h3>
<p>Les données personnelles des utilisateurs ne sont pas vendues ou louées à des tiers. Elles peuvent être partagées avec des prestataires de services tiers uniquement pour les besoins opérationnels de l'Application, et ce dans le respect du RGPD et de la LPD. Chaque tiers est soumis à une obligation contractuelle de respecter la confidentialité des données.</p>
<h3>5. Transfert International des Données</h3>
<p>Dans le cas où les données sont transférées vers des pays situés hors de l'Espace économique européen (EEE) ou de la Suisse, Be My Guest Connect veille à ce que ces transferts soient effectués en conformité avec les législations applicables, notamment via des clauses contractuelles types ou en s'assurant qu'une décision d'adéquation est en place (comme pour la Suisse).</p>
<h3>6. Durée de Conservation des Données</h3>
<p>Les données personnelles sont conservées aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette politique, sauf si une durée de conservation plus longue est requise ou permise par la loi suisse ou européenne.</p>
<h3>7. Droits des Utilisateurs</h3>
<p>Conformément aux législations sur la protection des données (RGPD et LPD), chaque utilisateur dispose des droits suivants concernant ses données personnelles :</p>
<p>Droit d'accès : Vous avez le droit de demander une copie de vos données personnelles.</p>
<p>Droit de rectification : Vous pouvez demander la correction de toute donnée inexacte ou incomplète.</p>
<p>Droit à l’effacement : Vous pouvez demander la suppression de vos données personnelles dans certaines circonstances.</p>
<p>Droit à la portabilité des données : Vous avez le droit de recevoir vos données dans un format structuré, couramment utilisé et lisible par machine.</p>
<p>Droit d’opposition : Vous pouvez vous opposer au traitement de vos données personnelles pour des motifs légitimes.</p>
<p>Droit de retrait du consentement : Vous pouvez retirer votre consentement à tout moment lorsque le traitement est fondé sur votre consentement.</p>
<p>Pour les utilisateurs résidant en Suisse, ces droits sont garantis par la LPD. Pour ceux résidant dans l'Union Européenne, ces droits sont garantis par le RGPD. Les demandes relatives à l’exercice de ces droits peuvent être adressées à l’adresse e-mail suivante : contact@bemyguest.ch.</p>
<h3>8. Sécurité des Données</h3>
<p>L'Application met en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger les données personnelles contre toute perte, utilisation non autorisée, accès, divulgation ou modification. Ces mesures sont conformes aux exigences du RGPD et de la LPD. Cependant, aucune méthode de transmission ou de stockage de données n’est totalement sécurisée, et nous ne pouvons garantir une sécurité absolue.</p>
<h3>9. Modification de la Politique de Confidentialité</h3>
<p>Cette politique de confidentialité peut être modifiée à tout moment. Toute modification sera publiée sur l'Application, et les utilisateurs seront informés des changements importants par e-mail ou via une notification dans l'Application. Il est recommandé de consulter régulièrement cette page pour prendre connaissance des mises à jour éventuelles.</p>
<h3>10. Contact</h3>
<p>Pour toute question relative à cette politique de confidentialité ou à la gestion de vos données personnelles, vous pouvez nous contacter à l'adresse suivante : Centre Administratif, Chemin des Polonais 16A, CH – 2016 Cortaillod.</p>
`,

cguHtml : `
<h2>Mentions légales</h2>
<h3>1. Préambule</h3>
<p>Les présentes Conditions Générales d'Utilisation (ci-après les « CGU ») régissent l’utilisation de l’application mobile Be My Guest Connect (ci-après « l’Application »), éditée par [Be My Guest Sàrl]. En téléchargeant, installant ou utilisant l’Application, l’utilisateur (ci-après « l’Utilisateur ») accepte sans réserve les présentes CGU.</p>
<h3>2. Objet de l’Application</h3>
<p>Be My Guest Connect a pour objet de mettre en relation les hôtes-ses directement avec les marques pour gérer leur accueil dans le cadre d'événements professionnels]. L’Application est disponible sur les systèmes d’exploitation iOS et Android.</p>
<h3>3. Accès à l'Application</h3>
<p>L'accès à l'Application est gratuit. Cependant, pour utiliser certaines fonctionnalités, il est nécessaire de créer un compte utilisateur. L’Utilisateur doit avoir atteint l'âge légal pour consentir à l’utilisation de ses données conformément aux lois locales applicables et garantir qu’il utilise l'Application de manière licite.</p>
<h3>4. Création de Compte</h3>
<p>Pour accéder à certaines fonctionnalités, l’Utilisateur doit créer un compte en fournissant des informations personnelles exactes et complètes. L’Utilisateur s'engage à :</p>
<p>·  Fournir des informations véridiques lors de l’inscription.</p>
<p>·  Maintenir la confidentialité de son identifiant et mot de passe.</p>
<p>·  Informer immédiatement l’éditeur en cas d'utilisation non autorisée de son compte.</p>
<p>L’éditeur se réserve le droit de suspendre ou de supprimer un compte en cas de non-respect des présentes CGU ou d'utilisation frauduleuse.</p>
<h3>5. Utilisation de l’Application</h3>
<p>L'Utilisateur s'engage à utiliser l’Application dans le respect des lois en vigueur et à ne pas :</p>
<p>·  Utiliser l’Application à des fins illégales, frauduleuses ou malveillantes.</p>
<p>·  Diffuser des contenus injurieux, diffamatoires, discriminatoires, ou portant atteinte aux droits des tiers.</p>
<p>·  Perturber le bon fonctionnement de l’Application, notamment par des actions de piratage.</p>
<p>L’éditeur se réserve le droit de retirer tout contenu ou de bloquer tout utilisateur qui ne respecterait pas ces règles.</p>
<h3>6. Responsabilités de l’Éditeur</h3>
<p>L'éditeur s'engage à mettre en œuvre tous les moyens raisonnables pour assurer un accès continu et sécurisé à l’Application. Toutefois, l'accès à l'Application peut être temporairement suspendu pour des raisons de maintenance ou de mises à jour techniques.</p>
<p>L’éditeur ne saurait être tenu responsable :</p>
<p>·  Des interruptions temporaires de l'Application pour maintenance ou mise à jour.</p>
<p>·  Des dommages indirects, pertes de profits ou pertes de données résultant de l’utilisation de l'Application.</p>
<p>·  Du contenu généré par les utilisateurs.</p>
<h3>7. Données Personnelles</h3>
<p>L’utilisation de l’Application implique la collecte et le traitement de données personnelles conformément à notre Politique de Confidentialité, laquelle est conforme au Règlement Général sur la Protection des Données (RGPD) ainsi qu'à la Loi Fédérale sur la Protection des Données (LPD) en Suisse. En utilisant l'Application, l'Utilisateur consent au traitement de ses données personnelles conformément à cette politique.</p>
<h3>8. Propriété Intellectuelle</h3>
<p>Tous les contenus disponibles sur l’Application, tels que les textes, graphiques, logos, images, sons, et logiciels, sont la propriété exclusive de Be My Guest ou/et de ses partenaires. Toute reproduction, modification, distribution, ou utilisation, en tout ou en partie, de ces contenus sans autorisation préalable est strictement interdite et constitue une contrefaçon sanctionnée par les lois applicables.</p>
<h3>9. Liens Hypertextes</h3>
<p>L'Application peut contenir des liens vers des sites web tiers. L'éditeur ne peut être tenu responsable des contenus, offres, ou pratiques de ces sites tiers. L'utilisateur accède à ces sites sous sa propre responsabilité.</p>
<h3>10. Modification des CGU</h3>
<p>L'éditeur se réserve le droit de modifier les présentes Conditions Générales d'Utilisation à tout moment. Les modifications entreront en vigueur dès leur publication sur l’Application. L’Utilisateur sera informé des changements importants par notification dans l’Application ou par e-mail. Il est recommandé de consulter régulièrement les CGU pour prendre connaissance des mises à jour éventuelles. L'utilisation continue de l'Application après les modifications constitue l'acceptation des nouvelles CGU.</p>
<h3>11. Droit Applicable et Juridiction Compétente</h3>
<p>Les présentes CGU sont régies par le droit suisse. En cas de litige, les parties s'engagent à rechercher une solution amiable. À défaut, tout différend sera soumis aux tribunaux compétents de Neuchâtel, Suisse.</p>
<h3>12. Dispositions Générales</h3>
<p>Si une disposition des présentes CGU est déclarée nulle ou inapplicable par un tribunal compétent, cela n'affectera pas la validité des autres dispositions, qui demeureront en vigueur. Les présentes CGU constituent l'intégralité de l'accord entre l’Utilisateur et l’éditeur concernant l'utilisation de l’Application, et remplacent tous les accords antérieurs.</p>
<h3>13. Contact</h3>
<p>Pour toute question relative aux présentes Conditions Générales d'Utilisation ou à l’utilisation de l’Application, l’Utilisateur peut contacter l’éditeur à l'adresse suivante : contact@bemyguest.ch ou par courrier : Be My Guest, Centre Administratif, Chemin des Polonais 16A, CH – 2016 Cortaillod</p>
`,

faqHtml : `
<h2>FAQ & Support</h2>
<h3>1. Informations Générales</h3>

<p class='annexe-question'>À quoi sert cette application ?</p>
<p>Cette application est conçue pour les hôtes et hôtesses afin de gérer leurs missions, communiquer via le chat et accéder aux informations de base de l’utilisateur nécessaires à leurs tâches.</p>

<p class='annexe-question'>Qui peut utiliser cette application ?</p>
<p>L'application est destinée aux hôtes et hôtesses enregistrés ainsi qu'aux utilisateurs approuvés nécessitant de l'aide pour la coordination d'événements ou de missions.</p>

<p class='annexe-question'>Comment puis-je télécharger l'application ?</p>
<p>Vous pouvez télécharger l'application depuis l'App Store pour iOS ou le Google Play Store pour les appareils Android.</p>

<h3>2. Compte et Profil</h3>

<p class='annexe-question'>Comment créer un compte ?</p>
<p>Pour créer un compte, téléchargez l'application et suivez le processus d'enregistrement à l'écran. Vous devrez entrer quelques informations de base et vérifier votre adresse e-mail.</p>

<p class='annexe-question'>Comment mettre à jour mes informations personnelles ?</p>
<p>Pour mettre à jour vos informations, rendez-vous dans l'onglet "Profil" et effectuez les modifications nécessaires. Assurez-vous que vos coordonnées sont à jour pour recevoir les notifications et missions actualisées.</p>

<p class='annexe-question'>Comment réinitialiser mon mot de passe ?</p>
<p>Sur l'écran de connexion, sélectionnez "Mot de passe oublié" et suivez les étapes pour le réinitialiser. Vous recevrez un lien par e-mail pour créer un nouveau mot de passe.</p>

<p class='annexe-question'>Comment mes données personnelles sont-elles utilisées et protégées ?</p>
<p>Nous priorisons votre confidentialité. Vos données sont stockées en toute sécurité et utilisées uniquement dans le cadre de vos missions et communications sur l'application. Pour plus de détails, consultez notre Politique de Confidentialité ci-dessous.</p>

<h3>3. Missions et Assignations</h3>

<p class='annexe-question'>Comment recevoir les notifications de mission ?</p>
<p>Lorsqu'une nouvelle mission est disponible, vous recevrez une notification push. Assurez-vous d'avoir activé les notifications dans les paramètres de l'application et de votre appareil.</p>

<p class='annexe-question'>Comment accepter une mission ?</p>
<p>Allez dans l'onglet "Missions", examinez les détails de la mission disponible, puis sélectionnez "Postuler à la mission". Une fois acceptée, la mission sera ajoutée à votre liste de missions actives.</p>

<p class='annexe-question'>Comment compléter une mission ?</p>
<p>Une fois que vous avez terminé toutes les tâches requises pour une mission, allez dans votre liste de missions actives et sélectionnez la mission complétée.</p>

<p class='annexe-question'>Comment consulter les missions complétées ?</p>
<p>Pour voir les missions passées, allez dans l'onglet "Terminées" sous la section Missions. Vous pouvez accéder ici aux détails de toutes les missions terminées.</p>

<p class='annexe-question'>Que faire si je ne peux pas compléter une mission ?</p>
<p>Si vous ne pouvez pas compléter une mission, informez votre coordinateur dès que possible via le chat intégré à l'application.</p>

<h3>4. Fonctionnalité de Chat</h3>

<p class='annexe-question'>Comment démarrer un chat avec un coordinateur ?</p>
<p>Sélectionnez l'onglet "Chat" pour démarrer une conversation. Tapez votre message et envoyez-le.</p>

<p class='annexe-question'>Puis-je discuter avec d'autres hôtes ou hôtesses ?</p>
<p>Le chat est uniquement disponible avec les coordinateurs associés à vos missions actives.</p>

<p class='annexe-question'>Comment savoir si j'ai un nouveau message ?</p>
<p>Vous recevrez une notification push pour chaque nouveau message. Assurez-vous que les notifications sont activées pour l'application dans les paramètres de votre appareil.</p>

<p class='annexe-question'>Que faire si mon chat ne fonctionne pas ?</p>
<p>Vérifiez votre connexion internet. Si le problème persiste, essayez de vous déconnecter, puis de vous reconnecter ou de redémarrer l'application. Si le problème continue, contactez notre support.</p>

<h3>5. Notifications et Rappels</h3>

<p class='annexe-question'>Comment puis-je gérer mes notifications ?</p>
<p>Allez dans "Paramètres" dans l'application pour contrôler les préférences de notification. Vous pouvez activer ou désactiver les notifications pour Be My Guest.</p>

<p class='annexe-question'>Pourquoi ne reçois-je pas de notifications ?</p>
<p>Assurez-vous que les notifications sont activées à la fois dans les paramètres de l'application et de votre appareil. Si le problème persiste, essayez de vous déconnecter et de vous reconnecter ou de réinstaller l'application.</p>

<h3>6. Dépannage</h3>

<p class='annexe-question'>L'application est lente ou ne charge pas, que faire ?</p>
<p>Vérifiez d'abord votre connexion internet. Si le problème continue, essayez de redémarrer l'application ou votre appareil. Si le problème persiste, mettez à jour l'application vers la dernière version ou contactez le support.</p>

<p class='annexe-question'>J'ai des problèmes pour me connecter. Que faire ?</p>
<p>Assurez-vous que vos identifiants de connexion sont corrects. Si vous avez oublié votre mot de passe, utilisez l'option "Mot de passe oublié". Pour d'autres problèmes de connexion, contactez le support.</p>

<p class='annexe-question'>Comment contacter le support ?</p>
<p>Pour obtenir de l'aide, vous pouvez nous contacter via l'onglet "Chat" ou sur notre site web.</p>

<h3>7. Confidentialité et Sécurité</h3>

<p class='annexe-question'>Mes informations sont-elles sécurisées ?</p>
<p>Oui, nous utilisons le cryptage et suivons les meilleures pratiques pour garantir la sécurité de vos données. Seules les parties autorisées peuvent accéder à vos informations, selon les besoins de l'application.</p>

<p class='annexe-question'>Puis-je supprimer mon compte ?</p>
<p>Pour demander la suppression de votre compte, allez dans "Paramètres" > "Supprimer le compte". Cela supprimera définitivement vos données de nos serveurs.</p>

<h3>8. Fonctionnalités Supplémentaires</h3>

<p class='annexe-question'>Puis-je personnaliser ma photo de profil ?</p>
<p>Oui, allez dans "Profil" > "Médias" et appuyez sur votre photo pour la changer. Vous pouvez télécharger une nouvelle photo depuis votre galerie ou prendre une photo directement via l'application.</p>

<p class='annexe-question'>L'application fonctionne-t-elle hors ligne ?</p>
<p>Non, pour les mises à jour en temps réel, les chats et les notifications, vous avez besoin d'une connexion internet.</p>
`
    },
    de : {
        privacyHtml : `
<h2>Datenschutzrichtlinie</h2>
<h3>1. Erhebung personenbezogener Daten</h3>
<p>Die Be My Guest Connect-Anwendung (im Folgenden "die Anwendung" genannt) erhebt und verarbeitet personenbezogene Daten gemäß den geltenden Datenschutzgesetzen, einschließlich der Datenschutz-Grundverordnung (DSGVO) der Europäischen Union und des Bundesgesetzes über den Datenschutz (DSG) in der Schweiz. Zu den gesammelten Daten gehören unter anderem Name, E-Mail-Adresse, Standortinformationen und alle anderen Informationen, die der Benutzer zur Nutzung der Dienste der Anwendung bereitstellt.</p>
<h3>2. Zweck der Datenverarbeitung</h3>
<p>Die über die Anwendung gesammelten personenbezogenen Daten werden für folgende Zwecke verwendet:</p>
<p>Bereitstellung und Verbesserung der von Be My Guest Connect angebotenen Dienste.</p>
<p>Erleichterung der Kontaktaufnahme zwischen den Benutzern.</p>
<p>Verwaltung von Unterstützungsgesuchen und Kommunikationen im Zusammenhang mit der Nutzung der Anwendung.</p>
<p>Einhaltung der geltenden gesetzlichen und regulatorischen Verpflichtungen in der Schweiz und der EU.</p>
<h3>3. Rechtliche Grundlage der Verarbeitung</h3>
<p>Die Verarbeitung personenbezogener Daten beruht auf mehreren rechtlichen Grundlagen, darunter:</p>
<p>Vertragserfüllung: Die Verarbeitung ist erforderlich, um einen Vertrag zu erfüllen, an dem der Benutzer beteiligt ist (für die Bereitstellung der Dienste der Anwendung).</p>
<p>Zustimmung: Bestimmte spezifische Funktionen erfordern die ausdrückliche Zustimmung des Benutzers.</p>
<p>Gesetzliche Verpflichtungen: Die Verarbeitung ist erforderlich, um den gesetzlichen Verpflichtungen nachzukommen, denen Be My Guest Connect gemäß den schweizerischen und europäischen Datenschutzgesetzen unterliegt.</p>
<h3>4. Datenweitergabe</h3>
<p>Die personenbezogenen Daten der Benutzer werden nicht an Dritte verkauft oder vermietet. Sie können nur mit Drittanbietern von Dienstleistungen für die betrieblichen Anforderungen der Anwendung und in Übereinstimmung mit der DSGVO und dem DSG geteilt werden. Jeder Dritte ist vertraglich verpflichtet, die Vertraulichkeit der Daten zu wahren.</p>
<h3>5. Internationaler Datentransfer</h3>
<p>Falls Daten in Länder außerhalb des Europäischen Wirtschaftsraums (EWR) oder der Schweiz übertragen werden, stellt Be My Guest Connect sicher, dass diese Übertragungen in Übereinstimmung mit den geltenden Gesetzen erfolgen, insbesondere durch Standardvertragsklauseln oder durch Gewährleistung, dass eine Angemessenheitsentscheidung vorliegt (wie für die Schweiz).</p>
<h3>6. Aufbewahrungsdauer der Daten</h3>
<p>Personenbezogene Daten werden so lange aufbewahrt, wie es erforderlich ist, um die in dieser Richtlinie beschriebenen Ziele zu erreichen, es sei denn, eine längere Aufbewahrungsfrist ist gesetzlich vorgeschrieben oder zulässig.</p>
<h3>7. Rechte der Benutzer</h3>
<p>Gemäß den Datenschutzgesetzen (DSGVO und DSG) hat jeder Benutzer die folgenden Rechte in Bezug auf seine personenbezogenen Daten:</p>
<p>Recht auf Zugang: Sie haben das Recht, eine Kopie Ihrer personenbezogenen Daten zu verlangen.</p>
<p>Recht auf Berichtigung: Sie können die Berichtigung von ungenauen oder unvollständigen Daten verlangen.</p>
<p>Recht auf Löschung: Sie können in bestimmten Fällen die Löschung Ihrer personenbezogenen Daten verlangen.</p>
<p>Recht auf Datenübertragbarkeit: Sie haben das Recht, Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.</p>
<p>Recht auf Widerspruch: Sie können der Verarbeitung Ihrer personenbezogenen Daten aus berechtigten Gründen widersprechen.</p>
<p>Recht auf Widerruf der Einwilligung: Sie können Ihre Einwilligung jederzeit widerrufen, wenn die Verarbeitung auf Ihrer Einwilligung beruht.</p>
<p>Für Benutzer mit Wohnsitz in der Schweiz sind diese Rechte durch das DSG garantiert. Für diejenigen, die in der Europäischen Union wohnen, sind diese Rechte durch die DSGVO garantiert. Anfragen zur Ausübung dieser Rechte können an die folgende E-Mail-Adresse gesendet werden: contact@bemyguest.ch.</p>
<h3>8. Datensicherheit</h3>
<p>Die Anwendung setzt geeignete technische und organisatorische Sicherheitsmaßnahmen um, um personenbezogene Daten vor Verlust, unbefugter Verwendung, Zugriff, Offenlegung oder Änderung zu schützen. Diese Maßnahmen entsprechen den Anforderungen der DSGVO und des DSG. Es ist jedoch keine Methode der Datenübertragung oder -speicherung völlig sicher, und wir können keine absolute Sicherheit garantieren.</p>
<h3>9. Änderungen der Datenschutzrichtlinie</h3>
<p>Diese Datenschutzrichtlinie kann jederzeit geändert werden. Änderungen werden in der Anwendung veröffentlicht, und die Benutzer werden über wesentliche Änderungen per E-Mail oder über eine Benachrichtigung in der Anwendung informiert. Es wird empfohlen, diese Seite regelmäßig auf mögliche Aktualisierungen zu überprüfen.</p>
<h3>10. Kontakt</h3>
<p>Bei Fragen zu dieser Datenschutzrichtlinie oder zur Verwaltung Ihrer personenbezogenen Daten können Sie uns unter folgender Adresse kontaktieren: Verwaltungszentrum, Chemin des Polonais 16A, CH – 2016 Cortaillod.</p>
  `,

  cguHtml : `
  <h2>Rechtliche Hinweise</h2>
<h3>1. Präambel</h3>
<p>Diese Allgemeinen Nutzungsbedingungen (im Folgenden "ANB") regeln die Nutzung der mobilen Be My Guest Connect-Anwendung (im Folgenden "die Anwendung"), herausgegeben von [Be My Guest Sàrl]. Durch das Herunterladen, Installieren oder Verwenden der Anwendung akzeptiert der Benutzer (im Folgenden "der Benutzer") diese ANB vorbehaltlos.</p>
<h3>2. Zweck der Anwendung</h3>
<p>Be My Guest Connect hat das Ziel, Gastgeber direkt mit Marken zu verbinden, um deren Empfang im Rahmen von beruflichen Veranstaltungen zu verwalten. Die Anwendung ist auf den Betriebssystemen iOS und Android verfügbar.</p>
<h3>3. Zugang zur Anwendung</h3>
<p>Der Zugang zur Anwendung ist kostenlos. Um jedoch bestimmte Funktionen zu nutzen, ist es notwendig, ein Benutzerkonto zu erstellen. Der Benutzer muss das gesetzliche Mindestalter erreicht haben, um der Verwendung seiner Daten gemäß den geltenden lokalen Gesetzen zuzustimmen, und sicherstellen, dass er die Anwendung rechtmäßig nutzt.</p>
<h3>4. Erstellung eines Kontos</h3>
<p>Um auf bestimmte Funktionen zugreifen zu können, muss der Benutzer ein Konto erstellen und genaue und vollständige persönliche Informationen angeben. Der Benutzer verpflichtet sich:</p>
<p>· Wahrheitsgemäße Informationen bei der Registrierung bereitzustellen.</p>
<p>· Die Vertraulichkeit seines Benutzernamens und Passworts zu wahren.</p>
<p>· Den Herausgeber sofort zu informieren, falls unbefugte Nutzung seines Kontos vorliegt.</p>
<p>Der Herausgeber behält sich das Recht vor, ein Konto bei Verstoß gegen diese ANB oder bei betrügerischer Nutzung zu sperren oder zu löschen.</p>
<h3>5. Nutzung der Anwendung</h3>
<p>Der Benutzer verpflichtet sich, die Anwendung im Einklang mit den geltenden Gesetzen zu verwenden und nicht:</p>
<p>· Die Anwendung für illegale, betrügerische oder schädliche Zwecke zu verwenden.</p>
<p>· Anstößige, verleumderische, diskriminierende Inhalte zu verbreiten oder die Rechte Dritter zu verletzen.</p>
<p>· Den ordnungsgemäßen Betrieb der Anwendung, insbesondere durch Hacking-Aktionen, zu stören.</p>
<p>Der Herausgeber behält sich das Recht vor, Inhalte zu entfernen oder Benutzer zu sperren, die gegen diese Regeln verstoßen.</p>
<h3>6. Verantwortlichkeiten des Herausgebers</h3>
<p>Der Herausgeber verpflichtet sich, alle angemessenen Maßnahmen zu ergreifen, um einen kontinuierlichen und sicheren Zugang zur Anwendung zu gewährleisten. Der Zugang zur Anwendung kann jedoch vorübergehend aus Wartungs- oder technischen Aktualisierungsgründen unterbrochen werden.</p>
<p>Der Herausgeber kann nicht verantwortlich gemacht werden für:</p>
<p>· Vorübergehende Unterbrechungen der Anwendung aufgrund von Wartungsarbeiten oder Aktualisierungen.</p>
<p>· Indirekte Schäden, Gewinnverluste oder Datenverluste, die aus der Nutzung der Anwendung resultieren.</p>
<p>· Vom Benutzer generierte Inhalte.</p>
<h3>7. Personenbezogene Daten</h3>
<p>Die Nutzung der Anwendung beinhaltet die Erhebung und Verarbeitung personenbezogener Daten gemäß unserer Datenschutzrichtlinie, die mit der Datenschutz-Grundverordnung (DSGVO) sowie dem Bundesgesetz über den Datenschutz (DSG) in der Schweiz übereinstimmt. Durch die Nutzung der Anwendung stimmt der Benutzer der Verarbeitung seiner personenbezogenen Daten gemäß dieser Richtlinie zu.</p>
<h3>8. Geistiges Eigentum</h3>
<p>Alle Inhalte, die in der Anwendung verfügbar sind, wie Texte, Grafiken, Logos, Bilder, Klänge und Software, sind das ausschließliche Eigentum von Be My Guest oder/und seinen Partnern. Jede Vervielfältigung, Modifikation, Verbreitung oder Nutzung, ganz oder teilweise, dieser Inhalte ohne vorherige Genehmigung ist strengstens untersagt und stellt eine Verletzung dar, die nach geltendem Recht geahndet wird.</p>
<h3>9. Hyperlinks</h3>
<p>Die Anwendung kann Links zu Websites Dritter enthalten. Der Herausgeber kann nicht für die Inhalte, Angebote oder Praktiken dieser Drittanbieter-Websites verantwortlich gemacht werden. Der Benutzer greift auf diese Seiten auf eigene Verantwortung zu.</p>
<h3>10. Änderung der ANB</h3>
<p>Der Herausgeber behält sich das Recht vor, diese Allgemeinen Nutzungsbedingungen jederzeit zu ändern. Änderungen treten in Kraft, sobald sie in der Anwendung veröffentlicht werden. Der Benutzer wird über wesentliche Änderungen durch Benachrichtigung in der Anwendung oder per E-Mail informiert. Es wird empfohlen, die ANB regelmäßig zu konsultieren, um über mögliche Aktualisierungen informiert zu sein. Die fortgesetzte Nutzung der Anwendung nach den Änderungen gilt als Akzeptanz der neuen ANB.</p>
<h3>11. Anwendbares Recht und zuständige Gerichtsbarkeit</h3>
<p>Diese ANB unterliegen dem Schweizer Recht. Im Streitfall verpflichten sich die Parteien, eine gütliche Lösung zu suchen. Andernfalls wird jeder Streitfall den zuständigen Gerichten in Neuchâtel, Schweiz, vorgelegt.</p>
<h3>12. Allgemeine Bestimmungen</h3>
<p>Wenn eine Bestimmung dieser ANB von einem zuständigen Gericht für nichtig oder nicht durchsetzbar erklärt wird, hat dies keine Auswirkungen auf die Gültigkeit der anderen Bestimmungen, die weiterhin in Kraft bleiben. Diese ANB bilden die vollständige Vereinbarung zwischen dem Benutzer und dem Herausgeber hinsichtlich der Nutzung der Anwendung und ersetzen alle vorherigen Vereinbarungen.</p>
<h3>13. Kontakt</h3>
<p>Für Fragen zu diesen Allgemeinen Nutzungsbedingungen oder zur Nutzung der Anwendung kann der Benutzer den Herausgeber unter folgender Adresse kontaktieren: contact@bemyguest.ch oder per Post: Be My Guest, Verwaltungszentrum, Chemin des Polonais 16A, CH – 2016 Cortaillod.</p>
  `,
faqHtml : `
<h2>FAQ & Unterstützung</h2>
<h3>1. Allgemeine Informationen</h3>

<p class='annexe-question'>Wofür ist diese App gedacht?</p>
<p>Diese App wurde für Hosts & Hostessen entwickelt, um Missionen zu verwalten, über den Chat zu kommunizieren und grundlegende Benutzerinformationen für ihre Aufgaben abzurufen.</p>

<p class='annexe-question'>Wer kann diese App nutzen?</p>
<p>Die App ist für registrierte Hosts & Hostessen und genehmigte Benutzer gedacht, die Unterstützung bei der Koordination von Events oder Missionen benötigen.</p>

<p class='annexe-question'>Wie lade ich die App herunter?</p>
<p>Sie können die App im App Store für iOS oder im Google Play Store für Android-Geräte herunterladen.</p>

<h3>2. Konto und Profil</h3>

<p class='annexe-question'>Wie erstelle ich ein Konto?</p>
<p>Um ein Konto zu erstellen, laden Sie die App herunter und folgen Sie dem Registrierungsvorgang auf dem Bildschirm. Sie müssen einige grundlegende Informationen eingeben und Ihre E-Mail-Adresse bestätigen.</p>

<p class='annexe-question'>Wie kann ich meine persönlichen Daten aktualisieren?</p>
<p>Um Ihre Daten zu aktualisieren, gehen Sie zur Registerkarte „Profil“ und nehmen Sie die gewünschten Änderungen vor. Stellen Sie sicher, dass Ihre Kontaktdaten auf dem neuesten Stand sind, um wichtige Benachrichtigungen und aktuelle Missionen zu erhalten.</p>

<p class='annexe-question'>Wie setze ich mein Passwort zurück?</p>
<p>Wählen Sie auf dem Anmeldebildschirm „Passwort vergessen“ und folgen Sie den Schritten zur Zurücksetzung. Sie erhalten per E-Mail einen Link, um ein neues Passwort zu erstellen.</p>

<p class='annexe-question'>Wie werden meine persönlichen Daten verwendet und geschützt?</p>
<p>Wir legen großen Wert auf Ihre Privatsphäre. Ihre Daten werden sicher gespeichert und nur für Zwecke im Zusammenhang mit Ihren Aufgaben und der Kommunikation in der App verwendet. Weitere Informationen finden Sie in unserer untenstehenden Datenschutzrichtlinie.</p>

<h3>3. Missionen und Aufgaben</h3>

<p class='annexe-question'>Wie erhalte ich Missionsbenachrichtigungen?</p>
<p>Wenn eine neue Mission verfügbar ist, erhalten Sie eine Push-Benachrichtigung. Stellen Sie sicher, dass die Benachrichtigungen in den App- und Geräteeinstellungen aktiviert sind.</p>

<p class='annexe-question'>Wie akzeptiere ich eine Mission?</p>
<p>Gehen Sie zur Registerkarte „Missionen“, überprüfen Sie die Details einer verfügbaren Mission und wählen Sie „Mission bewerben“. Nach Annahme wird die Mission zu Ihrer Liste der aktiven Missionen hinzugefügt.</p>

<p class='annexe-question'>Wie beende ich eine Mission?</p>
<p>Nachdem Sie alle erforderlichen Aufgaben für eine Mission abgeschlossen haben, gehen Sie zu Ihrer Liste aktiver Missionen und wählen Sie die abgeschlossene Mission aus.</p>

<p class='annexe-question'>Wie sehe ich abgeschlossene Missionen?</p>
<p>Um vergangene Missionen anzuzeigen, gehen Sie zur Registerkarte „Abgeschlossen“ im Missionsbereich. Hier können Sie die Details aller abgeschlossenen Aufgaben einsehen.</p>

<p class='annexe-question'>Was soll ich tun, wenn ich eine Mission nicht abschließen kann?</p>
<p>Falls Sie eine Mission nicht abschließen können, informieren Sie so bald wie möglich Ihren Koordinator über den In-App-Chat.</p>

<h3>4. Chat-Funktion</h3>

<p class='annexe-question'>Wie starte ich einen Chat mit einem Koordinator?</p>
<p>Wählen Sie die Registerkarte „Chat“, um eine Konversation zu beginnen. Geben Sie Ihre Nachricht ein und senden Sie sie.</p>

<p class='annexe-question'>Kann ich mit anderen Hosts oder Hostessen chatten?</p>
<p>Der Chat ist nur mit den Koordinatoren Ihrer aktiven Missionen verfügbar.</p>

<p class='annexe-question'>Wie erfahre ich, ob ich eine neue Nachricht habe?</p>
<p>Sie erhalten eine Push-Benachrichtigung für jede neue Nachricht. Stellen Sie sicher, dass die Benachrichtigungen für die App in den Einstellungen Ihres Geräts aktiviert sind.</p>

<p class='annexe-question'>Was tun, wenn mein Chat nicht funktioniert?</p>
<p>Überprüfen Sie Ihre Internetverbindung. Wenn das Problem weiterhin besteht, melden Sie sich ab und erneut an oder starten Sie die App neu. Falls das Problem weiterhin besteht, wenden Sie sich an den Support.</p>

<h3>5. Benachrichtigungen und Erinnerungen</h3>

<p class='annexe-question'>Wie kann ich meine Benachrichtigungen verwalten?</p>
<p>Gehen Sie zu „Einstellungen“ in der App, um die Benachrichtigungseinstellungen anzupassen. Sie können Benachrichtigungen für Be My Guest aktivieren oder deaktivieren.</p>

<p class='annexe-question'>Warum erhalte ich keine Benachrichtigungen?</p>
<p>Stellen Sie sicher, dass Benachrichtigungen sowohl in den App- als auch in den Geräteeinstellungen aktiviert sind. Wenn das Problem weiterhin besteht, melden Sie sich ab und erneut an oder installieren Sie die App neu.</p>

<h3>6. Fehlerbehebung</h3>

<p class='annexe-question'>Die App ist langsam oder lädt nicht - was kann ich tun?</p>
<p>Überprüfen Sie zunächst Ihre Internetverbindung. Wenn das Problem weiterhin besteht, versuchen Sie, die App oder Ihr Gerät neu zu starten. Sollte das Problem weiterhin bestehen, aktualisieren Sie die App auf die neueste Version oder wenden Sie sich an den Support.</p>

<p class='annexe-question'>Ich habe Probleme bei der Anmeldung. Was kann ich tun?</p>
<p>Stellen Sie sicher, dass Ihre Anmeldeinformationen korrekt sind. Wenn Sie Ihr Passwort vergessen haben, verwenden Sie die Option „Passwort vergessen“. Bei weiteren Anmeldeproblemen wenden Sie sich an den Support.</p>

<p class='annexe-question'>Wie kann ich den Support kontaktieren?</p>
<p>Für Hilfe können Sie uns über die Registerkarte „Chat“ oder unsere Website kontaktieren.</p>

<h3>7. Datenschutz und Sicherheit</h3>

<p class='annexe-question'>Sind meine Daten sicher?</p>
<p>Ja, wir verwenden Verschlüsselung und folgen bewährten Verfahren, um die Sicherheit Ihrer Daten zu gewährleisten. Nur autorisierte Parteien haben Zugriff auf Ihre Informationen, soweit dies für die Funktionalität der App erforderlich ist.</p>

<p class='annexe-question'>Kann ich mein Konto löschen?</p>
<p>Um die Löschung Ihres Kontos anzufordern, gehen Sie zu „Einstellungen“ > „Konto löschen“. Dadurch werden Ihre Daten dauerhaft von unseren Servern entfernt.</p>

<h3>8. Zusätzliche Funktionen</h3>

<p class='annexe-question'>Kann ich mein Profilbild anpassen?</p>
<p>Ja, gehen Sie zu „Profil“ > „Medien“ und tippen Sie auf Ihr Foto, um es zu ändern. Sie können ein neues Bild aus Ihrer Galerie hochladen oder ein Foto direkt in der App aufnehmen.</p>

<p class='annexe-question'>Funktioniert die App offline?</p>
<p>Nein, für Echtzeit-Updates, Chats und Benachrichtigungen ist eine Internetverbindung erforderlich.</p>
`
    },
    it : {
        privacyHtml : `
<h2>Politica sulla privacy</h2>
<h3>1. Raccolta di Dati Personali</h3>
<p>L'applicazione Be My Guest Connect (di seguito denominata "l'Applicazione") raccoglie e tratta dati personali in conformità con le leggi applicabili sulla protezione dei dati, inclusi il Regolamento Generale sulla Protezione dei Dati (GDPR) dell'Unione Europea e la Legge Federale sulla Protezione dei Dati (LPD) in Svizzera. I dati raccolti includono, ma non sono limitati a, nome, indirizzo e-mail, informazioni sulla posizione e qualsiasi altra informazione fornita dall'utente per utilizzare i servizi dell'Applicazione.</p>
<h3>2. Finalità del Trattamento dei Dati</h3>
<p>I dati personali raccolti tramite l'Applicazione sono utilizzati per le seguenti finalità:</p>
<p>Fornire e migliorare i servizi offerti da Be My Guest Connect.</p>
<p>Facilitare la connessione tra gli utenti.</p>
<p>Gestire le richieste di assistenza e le comunicazioni relative all'uso dell'Applicazione.</p>
<p>Conformarsi agli obblighi legali e normativi vigenti in Svizzera e nell'UE.</p>
<h3>3. Base Legale del Trattamento</h3>
<p>Il trattamento dei dati personali si basa su diverse basi legali, tra cui:</p>
<p>Espletamento del contratto: Il trattamento è necessario per l'esecuzione di un contratto al quale l'utente è parte (per la fornitura dei servizi dell'Applicazione).</p>
<p>Consenso: Alcune funzionalità specifiche richiedono il consenso esplicito dell'utente.</p>
<p>Obblighi legali: Il trattamento è richiesto per soddisfare le obbligazioni legali a cui Be My Guest Connect è soggetto ai sensi delle leggi svizzere ed europee sulla protezione dei dati.</p>
<h3>4. Condivisione dei Dati</h3>
<p>I dati personali degli utenti non vengono venduti o affittati a terzi. Possono essere condivisi con fornitori di servizi di terze parti solo per le necessità operative dell'Applicazione e nel rispetto del GDPR e della LPD. Ogni terzo è soggetto a un obbligo contrattuale di rispettare la riservatezza dei dati.</p>
<h3>5. Trasferimento Internazionale dei Dati</h3>
<p>Nel caso in cui i dati vengano trasferiti in paesi al di fuori dello Spazio Economico Europeo (SEE) o della Svizzera, Be My Guest Connect si assicura che tali trasferimenti avvengano in conformità alle leggi applicabili, in particolare attraverso clausole contrattuali standard o assicurandosi che sia in vigore una decisione di adeguatezza (come per la Svizzera).</p>
<h3>6. Durata della Conservazione dei Dati</h3>
<p>I dati personali vengono conservati per il tempo necessario a raggiungere gli obiettivi descritti in questa politica, salvo che un periodo di conservazione più lungo sia richiesto o consentito dalla legge svizzera o europea.</p>
<h3>7. Diritti degli Utenti</h3>
<p>In conformità con le leggi sulla protezione dei dati (GDPR e LPD), ogni utente ha i seguenti diritti riguardanti i propri dati personali:</p>
<p>Diritto di accesso: Hai il diritto di richiedere una copia dei tuoi dati personali.</p>
<p>Diritto di rettifica: Puoi richiedere la correzione di eventuali dati inaccurati o incompleti.</p>
<p>Diritto alla cancellazione: Puoi richiedere la cancellazione dei tuoi dati personali in determinate circostanze.</p>
<p>Diritto alla portabilità dei dati: Hai il diritto di ricevere i tuoi dati in un formato strutturato, di uso comune e leggibile da macchina.</p>
<p>Diritto di opposizione: Puoi opporti al trattamento dei tuoi dati personali per motivi legittimi.</p>
<p>Diritto di revoca del consenso: Puoi revocare il tuo consenso in qualsiasi momento quando il trattamento si basa sul tuo consenso.</p>
<p>Per gli utenti residenti in Svizzera, questi diritti sono garantiti dalla LPD. Per coloro che risiedono nell'Unione Europea, questi diritti sono garantiti dal GDPR. Le richieste relative all'esercizio di questi diritti possono essere inviate al seguente indirizzo email: contact@bemyguest.ch.</p>
<h3>8. Sicurezza dei Dati</h3>
<p>L'Applicazione attua misure di sicurezza tecniche e organizzative appropriate per proteggere i dati personali da perdita, utilizzo non autorizzato, accesso, divulgazione o modifica. Queste misure sono conformi ai requisiti del GDPR e della LPD. Tuttavia, nessun metodo di trasmissione o di archiviazione dei dati è completamente sicuro, e non possiamo garantire una sicurezza assoluta.</p>
<h3>9. Modifiche alla Politica sulla Privacy</h3>
<p>Questa politica sulla privacy può essere modificata in qualsiasi momento. Qualsiasi modifica sarà pubblicata sull'Applicazione e gli utenti saranno informati di eventuali modifiche significative tramite email o tramite una notifica nell'Applicazione. Si consiglia di controllare regolarmente questa pagina per eventuali aggiornamenti.</p>
<h3>10. Contatti</h3>
<p>Per qualsiasi domanda relativa a questa politica sulla privacy o alla gestione dei tuoi dati personali, puoi contattarci al seguente indirizzo: Centro Amministrativo, Chemin des Polonais 16A, CH – 2016 Cortaillod.</p>
`,

  cguHtml : `
  <h2>Informazioni Legali</h2>
<h3>1. Premessa</h3>
<p>Le presenti Condizioni Generali di Utilizzo (di seguito "CGU") regolano l'uso dell'applicazione mobile Be My Guest Connect (di seguito "l'Applicazione"), pubblicata da [Be My Guest Sàrl]. Scaricando, installando o utilizzando l'Applicazione, l'utente (di seguito "l'Utente") accetta senza riserve le presenti CGU.</p>
<h3>2. Scopo dell'Applicazione</h3>
<p>Be My Guest Connect ha l'obiettivo di mettere in relazione gli host direttamente con i marchi per gestire la loro accoglienza nel contesto di eventi professionali. L'Applicazione è disponibile sui sistemi operativi iOS e Android.</p>
<h3>3. Accesso all'Applicazione</h3>
<p>L'accesso all'Applicazione è gratuito. Tuttavia, per utilizzare alcune funzionalità, è necessario creare un account utente. L'Utente deve aver raggiunto l'età legale per consentire l'uso dei propri dati in conformità con le leggi locali applicabili e garantire di utilizzare l'Applicazione in modo lecito.</p>
<h3>4. Creazione dell'Account</h3>
<p>Per accedere a determinate funzionalità, l'Utente deve creare un account fornendo informazioni personali accurate e complete. L'Utente si impegna a:</p>
<p>· Fornire informazioni veritiere durante la registrazione.</p>
<p>· Mantenere la riservatezza del proprio nome utente e password.</p>
<p>· Informare immediatamente l'editore in caso di utilizzo non autorizzato del proprio account.</p>
<p>L'editore si riserva il diritto di sospendere o cancellare un account in caso di non rispetto delle presenti CGU o di uso fraudolento.</p>
<h3>5. Utilizzo dell'Applicazione</h3>
<p>L'Utente si impegna a utilizzare l'Applicazione nel rispetto delle leggi vigenti e a non:</p>
<p>· Utilizzare l'Applicazione per scopi illegali, fraudolenti o malevoli.</p>
<p>· Diffondere contenuti offensivi, diffamatori, discriminatori, o lesivi dei diritti di terzi.</p>
<p>· Disturbare il corretto funzionamento dell'Applicazione, in particolare attraverso azioni di hacking.</p>
<p>L'editore si riserva il diritto di rimuovere qualsiasi contenuto o bloccare qualsiasi utente che non rispetti queste regole.</p>
<h3>6. Responsabilità dell'Editore</h3>
<p>L'editore si impegna a mettere in atto tutti i mezzi ragionevoli per garantire un accesso continuo e sicuro all'Applicazione. Tuttavia, l'accesso all'Applicazione può essere temporaneamente sospeso per motivi di manutenzione o aggiornamenti tecnici.</p>
<p>L'editore non può essere ritenuto responsabile per:</p>
<p>· Interruzioni temporanee dell'Applicazione per manutenzione o aggiornamenti.</p>
<p>· Danni indiretti, perdita di profitti o perdita di dati derivanti dall'uso dell'Applicazione.</p>
<p>· Contenuti generati dagli utenti.</p>
<h3>7. Dati Personali</h3>
<p>L'utilizzo dell'Applicazione comporta la raccolta e il trattamento di dati personali in conformità con la nostra Politica sulla Privacy, che è conforme al Regolamento Generale sulla Protezione dei Dati (GDPR) e alla Legge Federale sulla Protezione dei Dati (LPD) in Svizzera. Utilizzando l'Applicazione, l'Utente acconsente al trattamento dei propri dati personali in conformità con questa politica.</p>
<h3>8. Proprietà Intellettuale</h3>
<p>Tutti i contenuti disponibili sull'Applicazione, come testi, grafica, loghi, immagini, suoni e software, sono di esclusiva proprietà di Be My Guest o dei suoi partner. Qualsiasi riproduzione, modifica, distribuzione o utilizzo, in tutto o in parte, di questi contenuti senza previa autorizzazione è rigorosamente vietata e costituisce una violazione punita dalle leggi applicabili.</p>
<h3>9. Link Ipertestuali</h3>
<p>L'Applicazione può contenere link a siti web di terze parti. L'editore non può essere ritenuto responsabile per i contenuti, le offerte o le pratiche di questi siti di terzi. L'utente accede a questi siti a proprio rischio.</p>
<h3>10. Modifica delle CGU</h3>
<p>L'editore si riserva il diritto di modificare le presenti Condizioni Generali di Utilizzo in qualsiasi momento. Le modifiche entreranno in vigore non appena saranno pubblicate sull'Applicazione. L'Utente sarà informato di cambiamenti significativi tramite notifica nell'Applicazione o via email. Si consiglia di consultare regolarmente le CGU per essere a conoscenza di eventuali aggiornamenti. L'uso continuato dell'Applicazione dopo le modifiche costituisce accettazione delle nuove CGU.</p>
<h3>11. Legge Applicabile e Giurisdizione Competente</h3>
<p>Le presenti CGU sono regolate dalla legge svizzera. In caso di controversia, le parti si impegnano a cercare una soluzione amichevole. In mancanza, qualsiasi controversia sarà sottoposta ai tribunali competenti di Neuchâtel, Svizzera.</p>
<h3>12. Disposizioni Generali</h3>
<p>Se una disposizione delle presenti CGU è dichiarata nulla o inapplicabile da un tribunale competente, ciò non influisce sulla validità delle altre disposizioni, che rimarranno in vigore. Le presenti CGU costituiscono l'intero accordo tra l'Utente e l'editore riguardo all'uso dell'Applicazione e sostituiscono tutti i precedenti accordi.</p>
<h3>13. Contatto</h3>
<p>Per qualsiasi domanda relativa a queste Condizioni Generali di Utilizzo o all'utilizzo dell'Applicazione, l'Utente può contattare l'editore all'indirizzo seguente: contact@bemyguest.ch o per posta: Be My Guest, Centro Amministrativo, Chemin des Polonais 16A, CH – 2016 Cortaillod.</p>
`,
faqHtml : `
<h2>FAQ e Supporto</h2>
<h3>1. Informazioni Generali</h3>

<p class='annexe-question'>A cosa serve questa app?</p>
<p>Questa app è progettata per Host e Hostess, per gestire le missioni, comunicare tramite chat e accedere alle informazioni di base degli utenti necessarie per i loro incarichi.</p>

<p class='annexe-question'>Chi può utilizzare questa app?</p>
<p>L'app è destinata agli Host e alle Hostess registrati e agli utenti approvati che richiedono assistenza nella coordinazione di eventi o missioni.</p>

<p class='annexe-question'>Come posso scaricare l'app?</p>
<p>Puoi scaricare l'app dall'App Store per iOS o dal Google Play Store per dispositivi Android.</p>

<h3>2. Account e Profilo</h3>

<p class='annexe-question'>Come creo un account?</p>
<p>Per creare un account, scarica l'app e segui il processo di registrazione sullo schermo. Dovrai inserire alcune informazioni di base e verificare il tuo indirizzo email.</p>

<p class='annexe-question'>Come posso aggiornare le mie informazioni personali?</p>
<p>Per aggiornare le tue informazioni, vai alla scheda "Profilo" e apporta le modifiche necessarie. Assicurati che i tuoi dettagli di contatto siano aggiornati per ricevere notifiche importanti e missioni aggiornate.</p>

<p class='annexe-question'>Come faccio a reimpostare la mia password?</p>
<p>Nella schermata di accesso, seleziona "Password dimenticata" e segui i passaggi per reimpostarla. Riceverai un link via email per creare una nuova password.</p>

<p class='annexe-question'>Come vengono utilizzati e protetti i miei dati personali?</p>
<p>La tua privacy è la nostra priorità. I tuoi dati sono conservati in modo sicuro e utilizzati esclusivamente per scopi legati ai tuoi incarichi e alle comunicazioni tramite l'app. Per maggiori dettagli, consulta la nostra Politica sulla Privacy qui sotto.</p>

<h3>3. Missioni e Incarichi</h3>

<p class='annexe-question'>Come ricevo le notifiche delle missioni?</p>
<p>Quando è disponibile una nuova missione, riceverai una notifica push. Assicurati che le notifiche siano abilitate nelle impostazioni dell'app e del tuo dispositivo.</p>

<p class='annexe-question'>Come faccio ad accettare una missione?</p>
<p>Vai alla scheda "Missioni", rivedi i dettagli della missione disponibile e seleziona "Candidati per la missione". Una volta accettata, la missione verrà aggiunta alla tua lista di missioni attive.</p>

<p class='annexe-question'>Come completo una missione?</p>
<p>Una volta completate tutte le attività richieste per una missione, vai alla tua lista di missioni attive e seleziona la missione completata.</p>

<p class='annexe-question'>Come posso vedere le missioni completate?</p>
<p>Per visualizzare le missioni passate, vai alla scheda "Completate" nella sezione Missioni. Qui puoi accedere ai dettagli di tutti gli incarichi completati.</p>

<p class='annexe-question'>Cosa fare se non posso completare una missione?</p>
<p>Se non riesci a completare una missione, informa il tuo coordinatore il prima possibile utilizzando la chat in-app.</p>

<h3>4. Funzione Chat</h3>

<p class='annexe-question'>Come posso avviare una chat con un coordinatore?</p>
<p>Seleziona la scheda "Chat" per iniziare una conversazione. Digita il tuo messaggio e invialo.</p>

<p class='annexe-question'>Posso chattare con altri Host o Hostess?</p>
<p>La chat è disponibile solo con i coordinatori associati alle tue missioni attive.</p>

<p class='annexe-question'>Come faccio a sapere se ho un nuovo messaggio?</p>
<p>Riceverai una notifica push per ogni nuovo messaggio. Assicurati che le notifiche siano abilitate per l'app nelle impostazioni del tuo dispositivo.</p>

<p class='annexe-question'>Cosa fare se la chat non funziona?</p>
<p>Controlla la tua connessione internet. Se il problema persiste, prova a uscire e rientrare o a riavviare l'app. Se il problema continua, contatta il nostro supporto.</p>

<h3>5. Notifiche e Promemoria</h3>

<p class='annexe-question'>Come posso gestire le notifiche?</p>
<p>Vai su "Impostazioni" nell'app per controllare le preferenze di notifica. Puoi abilitare o disabilitare le notifiche per Be My Guest.</p>

<p class='annexe-question'>Perché non ricevo notifiche?</p>
<p>Assicurati che le notifiche siano abilitate sia nelle impostazioni dell'app che sul dispositivo. Se il problema persiste, prova a uscire e rientrare o a reinstallare l'app.</p>

<h3>6. Risoluzione dei Problemi</h3>

<p class='annexe-question'>L'app è lenta o non si carica, cosa devo fare?</p>
<p>Controlla prima la tua connessione internet. Se il problema continua, prova a riavviare l'app o il dispositivo. Se il problema persiste, aggiorna l'app all'ultima versione o contatta il supporto.</p>

<p class='annexe-question'>Ho difficoltà ad accedere. Cosa devo fare?</p>
<p>Assicurati che le tue credenziali di accesso siano corrette. Se hai dimenticato la password, usa l'opzione "Password dimenticata". Per altri problemi di accesso, contatta il supporto.</p>

<p class='annexe-question'>Come posso contattare il supporto?</p>
<p>Per assistenza, puoi contattarci tramite la scheda "Chat" o sul nostro sito web.</p>

<h3>7. Privacy e Sicurezza</h3>

<p class='annexe-question'>Le mie informazioni sono sicure?</p>
<p>Sì, utilizziamo la crittografia e seguiamo le migliori pratiche per garantire la sicurezza dei tuoi dati. Solo le parti autorizzate possono accedere alle tue informazioni come richiesto per il funzionamento dell'app.</p>

<p class='annexe-question'>Posso eliminare il mio account?</p>
<p>Per richiedere la cancellazione del tuo account, vai su "Impostazioni" > "Elimina Account". Questo eliminerà definitivamente i tuoi dati dai nostri server.</p>

<h3>8. Funzioni Aggiuntive</h3>

<p class='annexe-question'>Posso personalizzare la mia foto del profilo?</p>
<p>Sì, vai su "Profilo" > "Media" e tocca la tua foto per cambiarla. Puoi caricare una nuova foto dalla tua galleria o scattarne una direttamente tramite l'app.</p>

<p class='annexe-question'>L'app funziona offline?</p>
<p>No, per aggiornamenti in tempo reale, chat e notifiche, è necessaria una connessione internet.</p>
`
    }
}