import styles from './Dropdown.scss';
import React from 'react';
import { Select } from 'antd';

const Dropdown = ({ value, selectedValue = null, JSON, onChange, onSearch, placeholder }) => {
  // Map the JSON data into a structure expected by Ant Design's Select
  const options = JSON.map(item => ({
    value: item.code,  // This will be the value sent on change
    label: item.name   // This will be the visible label in the dropdown
  }));

  return (
    <Select
      showSearch
      placeholder={placeholder} // Placeholder text
      optionFilterProp="label" // Use 'label' for filtering the search
      onChange={onChange}      // Use the passed onChange handler from props
      onSearch={onSearch}      // Pass the onSearch prop for search functionality
      options={options}        // Pass the mapped options to the Select component
      value={selectedValue || undefined} // Ensure value is undefined if no selection is made, to show placeholder
      allowClear              // Add a clear button to reset selection
    />
  );
};

export default Dropdown;