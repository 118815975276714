import styles from './Member.scss';
import { useTranslation } from "react-i18next";
import Button from '../../ui/Button';
import IconSmall from '../../ui/IconSmall';
import TitleReveal from '../../ui/TitleReveal';
import TitleUp from '../../ui/TitleUp';
import Description from '../../ui/Description';
import { theme } from 'antd';

// Destructure button prop, with a default value of true
const Member = ({ button = true }) => {
  const { t, i18n } = useTranslation("global");

  return (
    <section className='width-100 member-wrapper flex white-bg'>
      <div className='constrain-width padding-sides'>
        <div className='member-grid'>
          <div className='member-title'>
            <TitleUp text={t("member.team.title")} theme='black' />
          </div>
          <div className='member-description'>
            <Description text={t("member.team.description")} theme='black' />
          {/* <Button theme="dark" title={t("member.team.button")} animate={false} to="https://app.plany.jobs/login?agency=bemyguest" /> */}
          </div>
          <div className='member-icons_grid'>
            <div className='member-icon_1'>
              <IconSmall title={t("member.team.icon-1.title")} description={t("member.team.icon-1.description")} icon="agenda.svg" alignement="left" theme="dark" size={true} />
            </div>
            <div className='member-icon_2'>
              <IconSmall title={t("member.team.icon-2.title")} description={t("member.team.icon-2.description")} icon="notif.svg" alignement="left" theme="dark" size={true} />
            </div>
            <div className='member-icon_3'>
              <IconSmall title={t("member.team.icon-3.title")} description={t("member.team.icon-3.description")} icon="money.svg" alignement="left" theme="dark" size={true} />
            </div>
            <div className='member-icon_4'>
              <IconSmall title={t("member.team.icon-4.title")} description={t("member.team.icon-4.description")} icon="smiley_2.svg" alignement="left" theme="dark" size={true} />
            </div>
          </div>
        </div>
        </div>
    </section>
  );
};

export default Member;