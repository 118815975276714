import styles from './Title.scss';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { gsap } from "gsap";

const Title = ( {text} ) => {

    const [t, i18n] = useTranslation("global")
    const titleRef = useRef(null);

    useEffect(() => {

        gsap.set(titleRef.current, { opacity: 0, yPercent: 100 })
        gsap.to(titleRef.current, {
            opacity: 1,
            yPercent: 0,
            duration: 0.6,
            delay: 2,
            ease: 'Quad.easeOut'
        });

    }, [])

    return (
        <div className='intro-title__wrapper'>
            <h1 className='intro-title' ref={titleRef}>{text}</h1>
        </div>          
    )
};
export default Title