import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { I18nextProvider } from "react-i18next";
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import global_de from "./translations/de/global.json";
import global_it from "./translations/it/global.json";

// Configuration de i18next
i18next
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false }, // Pas nécessaire pour React car il échappe par défaut
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG, // Default language
    resources: {
      en: { global: global_en },
      fr: { global: global_fr },
      de: { global: global_de },
      it: { global: global_it },
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'], // Querystring Must be first
      lookupQuerystring: 'lang', // Use "lang" as query parameter
      caches: ['localStorage', 'cookie'], // Cache detected language
    },
    debug: false, // Debug to check detection logs
  }, (err, t) => {
    if (err) {
      console.error('Error initializing i18next:', err);
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);
