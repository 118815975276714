
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import HelmetMeta from '../components/layouts/HelmetMeta';

import Hero from '../components/layouts/Hero';
import Form from '../components/layouts/Form';
import Priority from '../components/layouts/Priority';
import Footer from '../components/layouts/Footer';

const Client = () => {

  const [t, i18n] = useTranslation("global")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <main className="App">

        <HelmetMeta page="client"/>

        <Hero title={t("hero.client.title")} tagline={t("hero.client.tagline")} img='img/photos/young-woman_large3.jpg' height='110vh' svg='img/triangle.svg' />

        <Form />

        <Priority />

        <Footer />

    </main>
  );
}

export default Client;