import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next'; // Correct import for useTranslation

const TextArea = ({ value, defaultValue, required = true, title, description, placeHolder = ''}) => {
    const { t, i18n } = useTranslation("global");

    // If no description is passed, use the translation by default
    const defaultDescription = description || t("client.form.field-default");
    // Use useFormContext to access methods from React Hook Form
    const { register, formState: { errors } } = useFormContext();

    return (
        <div className='text-area'>
            <label htmlFor={value}>{title}</label>
            {/* Register the input field with React Hook Form */}
            <textarea 
                id={value}
                placeholder={placeHolder}
                {...register(value, { 
                    required: required,
                })} 
                defaultValue={defaultValue} // Set the initial value
                style={{ height: '200px', resize: 'vertical', width: '100%'}} // Set fixed height
            />
            {/* Display validation message based on form state */}
            {errors[value]?.type === 'required' 
                ? <h5 style={{color:'red'}}>{t("client.form.field-error")}</h5> 
                : <h5>{defaultDescription}</h5>
            }
        </div>
    );
};

export default TextArea;