import styles from './Alert.scss'
import { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";

const Alert =  ({ title, description, color, trigger }) => {
    
    const [mounted, setMounted] = useState(false);
    const alertRef = useRef(null);

    useEffect(() => {
        gsap.set(alertRef.current, { yPercent:-100 });
        if(mounted) {
            gsap.to(alertRef.current, {
                duration: 0.6,
                yPercent:0,
                ease: 'Quad.easeOut',
                onComplete: () => {
                    gsap.to(alertRef.current, {
                        duration: 0.6,
                        delay: 2,
                        yPercent:-100,
                        ease: 'Quad.easeIn',
                    });
                }
            });

        }else {
            setMounted(true);
        }
    }, [trigger])
      
    return (
        <div className='alert'style={{ backgroundColor: color }} ref={alertRef}>
            <div className='alert_content'>
                <h3 className='text-light'>{title}</h3>
                <p className='text-light'>{description}</p>
            </div>
        </div>
    );
};

export default Alert;