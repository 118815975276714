import styles from './Tagline.scss';

import React, { useEffect, useRef } from 'react';

import { gsap } from "gsap";

const Tagline = ( { text }) => {

    const taglineRef = useRef(null);

    useEffect(() => {

        gsap.set(taglineRef.current, { opacity: 0, yPercent: -100})
        gsap.to(taglineRef.current, {
            opacity: 0.6,
            yPercent: 0,
            duration: 0.6,
            delay: 2,
            ease: 'Quad.easeInOut'
        });

    }, [])

    return (
        <div className='intro-tagline__wrapper'>
            <h4 className='text-light' ref={taglineRef}>{text}</h4>
        </div>
    )
};
export default Tagline