
import React, {useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import HelmetMeta from '../components/layouts/HelmetMeta';

import { ListJobOffers } from '../services/Api'

import Hero from '../components/layouts/Hero';
import Member from '../components/layouts/Member';
import App from '../components/layouts/App';
import FAQ from '../components/layouts/FAQ';
import Tips from '../components/layouts/Tips';
import Jobs from '../components/layouts/Jobs';
import Footer from '../components/layouts/Footer';

const MemberPage = () => {

  const [t, i18n] = useTranslation("global")

  const [jobOffers, setJobOffers] = useState([]);

  // Retrieve BMG Job Offers

  const getJobOffers = async () =>{
    const JOResults = await ListJobOffers(i18n.language);   
    setJobOffers(JOResults);
  };

  useEffect(() => {
    getJobOffers();
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="App">

        <HelmetMeta page="member"/>
      
        <Hero title={t("hero.member.title")} tagline={t("hero.member.tagline")} img='img/gallery/group-3.jpg' height='110vh' svg='img/triangle.svg' />

        <FAQ />

        <Tips />
        
        <Member button={false}/>

        <App />

        <Jobs jobOffers={jobOffers}/>

        <Footer />

    </main>
  );
}

export default MemberPage;