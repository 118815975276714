import styles from './Scroll.scss';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import gsap from 'gsap';

const Scroll = () => {

    const [t, i18n] = useTranslation("global")
    const scrollRef = useRef(null);

    useEffect(() => {

        gsap.set(scrollRef.current, { opacity: 0})
        gsap.to(scrollRef.current, {
            opacity: 0.8,
            duration: 3,
            delay: 2,
        });

    }, [])

    return (
        <div className='scroll-UI' ref={scrollRef}>
            <div className='scroll-UI__wrapper'>
                <div className='scroll-UI__line'></div>
            </div>
            <p className='scroll-UI__text'>Scroll</p>
        </div>
    )
};

export default Scroll