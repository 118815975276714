import React, { useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from "react-i18next";

import Button from '../../ui/Button';
import TextField from '../../ui/TextField';
import TextArea from '../../ui/TextArea';
import TitleReveal from '../../ui/TitleReveal';
import Alert from '../../ui/Alert';
import { SendContactForm } from '../../../services/Api';

const wait = function (duration = 1000) {
    return new Promise((resolve) => {
        window.setTimeout(resolve, duration);
    });
}

const ContactForm = () => {
    const { register, formState, handleSubmit } = useForm({mode: 'onTouched'});
    const { t, i18n } = useTranslation("global");

    const formRef = useRef();
    //const alertRef = useRef(null)

    // for multiple selection
    const [selectedLanguages, setSelectedLanguages] = useState(['en']);

    // for date picker
    const [date, setDate] = useState(new Date());

    const methods = useForm();

    // For the Alert component
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertContent, setAlertContent] = useState({
        title: '',
        description: '',
        color: '',
    });
    const [alertTrigger, setAlertTrigger] = useState(0);

    const showAlert = (title, description, color) => {
        setAlertContent({ title, description, color });
        setAlertTrigger(prev => prev + 1);
        setAlertVisible(true);
    };
    const onSubmit = async data => {        
        const sendFormResult = await SendContactForm(data); 
        if (sendFormResult) {
            methods.reset();
            showAlert(t("alert.good.title"), t("alert.good.description"), '#248320');
        } else {
            showAlert(t("alert.bad.title"), t("alert.bad.description"), '#248320');
        }        
    }

    return (
        <>
        <div className='width-100 client-wrapper flex white-bg'>
            <div className='constrain-width padding-sides'>
                <FormProvider {...methods}>
                    <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className='form-spacing'>
                            <div className='title'>
                                <TitleReveal text={t("contact.title")} theme='black' />
                            </div>
                            <div className='form-col'>
                                <TextField value='email' type='email' title={t("contact.email")}/>
                                <TextField value='subject' title={t("contact.subject")}/>
                                
                            </div>
                            <div className='form-col'>
                                <TextArea value='body' required={false} title={t("contact.body")}/>
                            </div>
                            <div style={{marginTop:'4em'}} className='flex'>
                                <Button type="submit" theme="dark" animate={false} title={t("button.form")} />
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
        <Alert
            title={alertContent.title}
            description={alertContent.description}
            color={alertContent.color}
            trigger={alertTrigger}
        />
    </>
    );
};

export default ContactForm;