
import React, { useEffect,useState } from 'react';
import HelmetMeta from '../components/layouts/HelmetMeta';

import { useTranslation } from "react-i18next";
import { legalHTML } from '../utils/constants';
import { NavLink } from 'react-router-dom';
import Footer from '../components/layouts/Footer';

const Support = () => {

  const [t, i18n] = useTranslation("global");
  const [contentHtml,setContentHtml] = useState({faqHtml:''});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (legalHTML && legalHTML[i18n.language]) {
      setContentHtml(legalHTML[i18n.language]);
    }
  }, [i18n.language]);

  return (
    <section className='width-100 flex white-bg'>

      <HelmetMeta page="support"/>
            
      <div className='constrain-width padding-sides'>
          <div className='annexe' dangerouslySetInnerHTML={{ __html: contentHtml.faqHtml }} />
      </div>
        <div className='annexe-menu'>
          <NavLink to='/legal' className='text-dark'><p>{t("footer.legal")}</p></NavLink>
          <p> - </p>
          <NavLink to='/privacy' className='text-dark'><p>{t("footer.privacy")}</p></NavLink>
          <p> - </p>
          <NavLink to='/contact' className='text-dark'><p>{t("hero.contact.title")}</p></NavLink>
        </div>
      <Footer />
    </section>
  );
}

export default Support;