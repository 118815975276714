import styles from './TitleReveal.scss';
import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const TitleReveal = ({ text, theme = "white" }) => {

    const scrollAnimationRef = useRef(null);

    useEffect(() => {
        // Scroll animation for title using ScrollTrigger
        gsap.fromTo(
            scrollAnimationRef.current.querySelector('span'),
            { backgroundSize: '0% 100%' }, // Initial state for background-size
            {
                backgroundSize: '100% 100%', // Target state for background-size
                ease: 'power3.out',
                duration: 1.5,
                scrollTrigger: {
                    trigger: scrollAnimationRef.current,
                    start: 'top+=50 bottom', // Start when the top of the element enters 100px of the bottom of the viewport
                    end: 'top+=300 bottom', // End when the top of the element enters 300px of the bottom of the viewport
                    toggleActions: 'play reverse play reverse', // Allow reverse animation on scroll up
                    scrub: true, // Synchronize animation with scroll position
                    markers: false, // Set to true if you want to debug
                },
            }
        );
    }, []);

    // Determine the dynamic class based on the theme
    const dynamicClass = theme === 'accent' ? 'reveal-animation-accent' : theme === 'black' ? 'reveal-animation-black' : 'reveal-animation-white';

    return (

        <h2 ref={scrollAnimationRef} className={`reveal-animation ${dynamicClass}`}>
            <span>{text}</span>
        </h2>

    );
};

export default TitleReveal;