import styles from './Description.scss';
import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Description = ({ text, theme = "white" }) => {
  // Determine the dynamic class based on the theme
  const dynamicClass = theme === 'accent' ? 'text-accent' : theme === 'black' ? 'text-dark' : 'text-white';
  const descriptionRef = useRef(null); // Reference to the paragraph element

  useEffect(() => {
    const element = descriptionRef.current; // Get the element reference

    if (element) {
      // GSAP animation for fading the description
      gsap.fromTo(
        element,
        { opacity: 0 }, // Initial state: hidden
        {
          opacity: 1, // Final state: visible
          ease: 'power3.out',
          duration: 1.5,
          scrollTrigger: {
            trigger: element,
            start: 'top+=100 bottom', // Start the animation when 100px of the element enters the bottom of the viewport
            end: 'top+=300 bottom', // End the animation when 300px of the element has entered the viewport
            toggleActions: 'play reverse play reverse', // Play and reverse the animation on scroll up/down
            scrub: true, // Smooth scrubbing with scroll
            markers: false, // Set to true to see the markers for debugging
          },
        }
      );
    }

    // Cleanup function to remove ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    
      <p ref={descriptionRef} className={`description ${dynamicClass}`}>{text}</p>
    
  );
};

export default Description;