// File: DateComponent.js

import React, { useState, useEffect } from 'react';
import './DateComponent.scss'; // Ensure the correct path to your CSS file
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { DatePicker, ConfigProvider } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// Import Ant Design locales
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';

dayjs.extend(updateLocale);

// Mapping for antd locales
const antdLocales = {
  en: enUS,
  fr: frFR,
};

const DateComponent = ({title, description, eventDate, setEventDate}) => {
  const { t, i18n } = useTranslation(); // Initialize i18next hook
  // const [days, setDays] = useState(null);

  // Change language dynamically
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    // Optionally, update locale globally if needed
    dayjs.locale(lang);
  };

  useEffect(() => {
    // Detect initial language from i18next
    const initialLang = i18n.language || 'en'; // Default to 'en' if no language detected
    dayjs.locale(initialLang); // Set dayjs locale
    i18n.changeLanguage(initialLang); // Set i18n language
  }, [i18n.language]);

  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };

  return (
    <div className='date'>
      <label htmlFor='date'>{title}</label>
    <ConfigProvider locale={antdLocales[i18n.language]}>
      <div className='date-component'>
        <DatePicker
          onChange={(date) => setEventDate(date)}
          value={eventDate}
          disabledDate={disabledDate}
        />
      </div>
      
    </ConfigProvider>
    <h5>{description}</h5>
    </div>
  );
};

export default DateComponent;