import styles from './Masonry.scss'
import React, { useState } from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const Images = ({ galleryPhotos }) => {
    const [hoveredImage, setHoveredImage] = useState(null);

    const handleMouseEnter = (key) => {
        setHoveredImage(key);
    };

    const handleMouseLeave = () => {
        setHoveredImage(null);
    };

    return (
        <section className='width-100 flex images-wrapper'>
            <div className='constrain-width padding-sides'>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                >
                    <Masonry gutter="6px">
                        {/* Reverse the galleryPhotos array */}
                        {galleryPhotos.slice().reverse().map((photo, index) => (
                            <div
                                key={index.toString()}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                className={hoveredImage === index ? 'images-content images-content_in' : 'images-content images-content_out'}
                            >
                                <img className='images' src={photo.image} alt={photo.title}  title={photo.title} />
                                <div className='images-text'>
                                    <h3>{photo.title}</h3>
                                    <h5>{photo.description}</h5>
                                </div>
                            </div>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </section>
    )
}

export default Images;