import Carousel2 from '../Carousel2';
import IconSmall from '../../ui/IconSmall';
import TitleReveal from '../../ui/TitleReveal';
import Description from '../../ui/Description';

//import styles from './Tips.scss'
import { useTranslation } from "react-i18next";

const Tips = ({standards}) => {
  const [t, i18n] = useTranslation("global")

    return (
        <section className='width-100 priority-wrapper flex'>
          
        <div className='constrain-width padding-sides priority-grid'>

        <Carousel2 images={['peliroja','tom', 'anna','marta','black', 'young-woman_7','asian','tatiana','agathe']} />
          
          <div className='priority-title'>
              <TitleReveal text={t("tips.title")} />
              <div className='priority-description'>
              <Description text={t("tips.description")} theme='white' />
              </div>
              <ul className='card-list flex-column'>
                <IconSmall title={t("tips.icon-1.title")} description={t("tips.icon-1.description")} icon="history.svg" alignement="left" theme="accent" />
                <IconSmall title={t("tips.icon-2.title")} description={t("tips.icon-2.description")} icon="camera.svg" alignement="left" theme="accent" />
                <IconSmall title={t("tips.icon-3.title")} description={t("tips.icon-3.description")} icon="stroke.svg" alignement="left" theme="accent" />
                <IconSmall title={t("tips.icon-4.title")} description={t("tips.icon-4.description")} icon="quality.svg" alignement="left" theme="accent" />
                <IconSmall title={t("tips.icon-5.title")} description={t("tips.icon-5.description")} icon="resolution.svg" alignement="left" theme="accent" />
              </ul>
          </div>
          
        </div>
        <img className='triangle-top' src='img/triangle.svg' alt=''></img>
        <img className='triangle-bottom' src='img/triangle.svg' alt=''></img>
      </section>
    )
}

export default Tips;